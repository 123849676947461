import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
  DefaultOptions,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { OAuthService } from 'angular-oauth2-oidc';
import { apiUrl } from './config';
import { HttpHeaders } from '@angular/common/http';


export function createApollo(
  this: void,
  httpLink: HttpLink,
  oauth: OAuthService
): Record<string, ApolloClientOptions<any>> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext(async (_, { headers }) => {
    let token = oauth ? oauth.getAccessToken() : '';

    if (!token) {
      await oauth.refreshToken();

      token = oauth.getAccessToken();
    }

    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('GraphQL-Preflight', '1'),
    };
  });
  const link = ApolloLink.from([basic, auth, httpLink.create({ uri: apiUrl })]);
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return {
    'sitosxapi': {
      link,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    }
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService],
    },
  ],
})
export class GraphQLModule { }
