import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { map } from 'rxjs/operators';
import { AuthService } from './core/services/auth/auth.service';
import { GetVersionGQL, GetVersionQuery } from './dashboard/services/getversion.query.sitos-x-api.g';
import GrowVersion from '../../grow_version.g.json'
import { appService } from './appService';
import { BehaviorSubject } from 'rxjs';

interface Version{
  semVer:string;
  releaseType:string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  defaultLocale: string = '';
  currentLocale: string = '';
  appAPIVersion:string='';
  appUIVersion:string='';
  growAppVersion:Version=GrowVersion;
  showScrollbar: string="always";
  renderScroolView$: BehaviorSubject<boolean> | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private getVersion:GetVersionGQL,
    public appService: appService,
  ) {
    appService.changeEmitted$.subscribe(modal => {
      if  ( modal='open' ){
        this.showScrollbar='never'
      }
    })

    this.renderScroolView$ = appService.scrollVisibility
  }

  ngOnInit() {
    // this.getApplicationVersion();
    this.addRouteChangeHandler();
    this.addLogOutHandler();
    this.addStorageHandler();
    this.appUIVersion=this.growAppVersion.semVer;
  }

  private getApplicationVersion(){
    this.getVersion.watch().valueChanges.pipe(map((res:ApolloQueryResult<GetVersionQuery>)=>{
      return res.data.version;
    })).subscribe((res)=>{
      this.appAPIVersion=res;
    })
    }
  private addRouteChangeHandler() {
    window.addEventListener('message', (e) => {
      if (e.data.type === 'route_change') {
        const urlTree = this.router.parseUrl(e.data.route);
        const url = new URL(e.data.route, 'https://dummy/');
        const route = url.pathname;
        this.router.navigate([route], { queryParams: urlTree.queryParams });
      }

    });
  }

  private addLogOutHandler() {
    this.authService.addLogOutMessageHandler();
  }

  private addStorageHandler() {
    this.authService.addStorageStateHandler();
  }
}
