export const environment = {
  production: true,
  baseUrl: "",
  envName: 'local',
  recommenderApiUrl: 'https://ai-xiting.know-center.at/sp/xiting/recomm',
  domainId: '59ab-mit',
  apiKey: '3SJ1jkDnoOofl4bTP98uroqH5KadgmP+h4Nc1u+nVvc=',
  scenarioName: 'kurs_cf',
  recommendationCount: 4
};
