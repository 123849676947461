import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {UserCountLoginService} from "../user/userCountLogin.service";

export { AuthService, AuthServiceConfig, AUTH_SERVICE_CONFIG_TOKEN };

@Injectable({
  providedIn: 'root',
})
class AuthService implements OnDestroy {
  private subscriptions$: Subscription[] = [];
  private logOutMessageSubscription?: Subscription;
  private storageStateSubscription?: Subscription;
  private isAuthenticationCompletedSubject$ = new BehaviorSubject<boolean>(
    false
  );
  public isAuthenticationCompleted$ =
    this.isAuthenticationCompletedSubject$.asObservable();
  constructor(
    private oauthService: OAuthService,
    @Inject(AUTH_SERVICE_CONFIG_TOKEN) private config: AuthServiceConfig,
    private userCountLoginService: UserCountLoginService,
  ) {
    //this.addEventLog();
    this.addSessionErrorCheck();
    this.configure(this.config);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public configure(config: AuthServiceConfig) {
    this.oauthService.configure(this.createAuthConfig(config));
  }

  public runInitialLoginSequence(): Promise<any> {
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return this.silentRefresh();
        } else {
          return this.tryLogin();
        }
      })
      .then(() => {
        this.isAuthenticationCompletedSubject$.next(true);
      })
      .catch(() => {
        this.oauthService.logOut(true);
        this.isAuthenticationCompletedSubject$.next(true);
      });
  }

  silentRefresh() {
    return this.oauthService.silentRefresh().then(() => {
      Promise.resolve();

      if (this.oauthService.hasValidAccessToken()){
        const isCountUserLogin= localStorage.getItem('count_user_login');
        if (!isCountUserLogin) {
          const value = "Count User Login";
          localStorage.setItem('count_user_login', value);
          // call the userCountLogin service for updating CountLogin on the successful user login
          this.userCountLoginService.updateCountUserLogin();
        }
      }
    });
  }

  tryLogin() {
    return this.oauthService.tryLogin().then(() => {
      // check request result instead of token and silent refresh. need to investigate
      if (
        !this.oauthService.hasValidIdToken() ||
        !this.oauthService.hasValidAccessToken()
      ) {
        return this.silentRefresh();
      } else {
        return Promise.resolve();
      }
    });
  }

  public initLogInFlow() {
    this.oauthService.initLoginFlow();
  }

  public logOut(noRedirect: boolean = false) {
    //TODO - clear apollo cache
    localStorage.removeItem('role_token_claims_obj');
    localStorage.removeItem('count_user_login');
    this.oauthService.logOut(noRedirect);
  }

  public parseJwt(token: any) {
    var base64Url = token?.split('.')[1];
    var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    if (base64) {
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    }
  }

  public get claims(): any {
    const roleClaims: string[] = this.parseJwt(this.oauthService.getAccessToken())?.resource_access['sitos-x-api']?.roles;
    localStorage.setItem('role_token_claims_obj', JSON.stringify(roleClaims));
    return this.oauthService.getIdentityClaims();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  public hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  public addLogOutMessageHandler() {
    if (this.logOutMessageSubscription) {
      return;
    }

    this.logOutMessageSubscription = fromEvent(window, 'message').subscribe(((
      e: MessageEvent
    ) => {
      if (e.data.type === 'log_out') {
        this.logOut(true);
      }
    }) as EventListener);
  }

  public removeLogOutMessageHandler() {
    if (this.logOutMessageSubscription) {
      this.logOutMessageSubscription.unsubscribe();
      this.logOutMessageSubscription = undefined;
    }
  }

  public addStorageStateHandler() {
    if (this.storageStateSubscription) {
      return;
    }
    let isReloading = false;
    const initialClaims = this.claims;
    this.storageStateSubscription = fromEvent(window, 'storage').subscribe(
      () => {
        const claims = this.claims;
        if (
          !isReloading &&
          initialClaims?.['session_state'] !== claims?.['session_state']
        ) {
          isReloading = true;
          window.location.reload();
        }
      }
    );
  }

  public removeStorageStateHandler() {
    if (this.storageStateSubscription) {
      this.storageStateSubscription.unsubscribe();
      this.storageStateSubscription = undefined;
    }
  }

  ngOnDestroy(): void {
    this.removeLogOutMessageHandler();
    this.removeStorageStateHandler();
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  private addSessionErrorCheck() {
    this.subscriptions$.push(
      this.oauthService.events
        .pipe(
          filter((e) =>
            ['session_terminated', 'session_error'].includes(e.type)
          )
        )
        .subscribe((e) => this.initLogInFlow())
    );
  }

  private addEventLog() {
    this.subscriptions$.push(
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuthErrorEvent Object:', event);
        } else {
          console.warn('OAuthEvent Object:', event);
        }
      })
    );
  }

  private createAuthConfig(params: AuthServiceConfig): AuthConfig {
    const issuer = params.issuer;
    const clientId = params.clientId;
    let scopes = (params.scope || '').split(/\s+/);
    const requiredScopes = ['openid', 'offline_access', 'profile', 'email'];
    for (const requiredScope of requiredScopes) {
      if (!scopes.find((x) => x === requiredScope)) {
        scopes.push(requiredScope);
      }
    }
    const scope = scopes.join(' ');

    const config: AuthConfig = {
      issuer,
      redirectUri: window.location.href.split('?')[0],
      postLogoutRedirectUri: window.location.origin,
      clientId,
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope,
      requireHttps: true,
      //dummyClientSecret,
      responseType: 'code',
      // useSilentRefresh: true,
    };
    return config;
  }
}

type AuthServiceConfig = {
  clientId: string;
  issuer: string;
  scope: string;
  //dummyClientSecret: string;
  requireHTTPS: string | boolean;
  responseType: string;
};

const AUTH_SERVICE_CONFIG_TOKEN = new InjectionToken<AuthServiceConfig>(
  'authService.config'
);
