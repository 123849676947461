import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  //Promise<boolean> {
  //   const token = await this.jwt.getAccessToken();
  //   if (token) {
  //     let parsedToken = await this.jwt.getAccessTokenParsed();
  //     const userRole: string[] = parsedToken.resource_access['sitos-x-api'].roles;
  //     this.SetLocalStorageValues(token, userRole, parsedToken);
  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      const userRole: string[] = JSON.parse(
        localStorage.getItem('role_token_claims_obj')!
      );
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        const intersectedRoles = userRole.filter((value) =>
          route.data.role.includes(value)
        );
        const isAuthorized = intersectedRoles.length ? true : false;
        if (!isAuthorized) {
          this.router.navigate(['/dashboard']);
          return false;
        }
        return true;
      }
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }

  // private SetLocalStorageValues(token: string, userRole: string[], parsedToken: JWTToken) {
  //   localStorage.setItem('access_token', token);
  //   localStorage.setItem('role_token_claims_obj', JSON.stringify(userRole));
  //   localStorage.setItem('access_token_claims_obj', JSON.stringify(parsedToken));
  //   localStorage.setItem('expires_at', parsedToken.exp.toString());
  // }
}
