import { InjectionToken } from "@angular/core";

export interface RecommenderConfiguration {
  recommenderApiUrl: string;
  domainId: string;
  apiKey: string;
  scenarioName: string;
  recommendationCount: number;
}


export const RECOMMENDER_CONFIGURATION = new InjectionToken<RecommenderConfiguration>('RECOMMENDER_CONFIGURATION')
