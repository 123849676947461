import { ThemeService } from "src/app/shared/services/theme.service";
import { AuthService } from "./auth/auth.service";
import { DateTimeService } from "./datetime.service";
import { TranslationService } from "./translation.service";



export function appInitializer(authService: AuthService, translation: TranslationService, dateTime: DateTimeService, themeService: ThemeService) {
    return () =>
        authService.runInitialLoginSequence().finally(() => {
            if (authService.claims) {
                // datetime settings
                const userDateFormat = authService.claims ? authService.claims['date_format'] : null;
                const realmDateFormat = authService.claims ? authService.claims['default_date_format'] : null;
                dateTime.setUserDateFormat(userDateFormat);
                dateTime.setRealmDateFormat(realmDateFormat);

                // theme settings
                const themeName = authService.claims['theme'] ?? '';
                themeService.switchLocalTheme(themeName);
                themeService.setAppTheme(themeName);

                // translation settings
                const userLocale = authService.claims ? authService.claims['locale'] : null;
                const defaultRealmLocale = authService.claims ? authService.claims['default_locale'] : null;
                translation.setUserLocale(userLocale);
                translation.setDefaultRealmLocale(defaultRealmLocale);
            }
            return translation.loadMessages().toPromise();
        });
}
