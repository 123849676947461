import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale, loadMessages } from 'devextreme/localization';
import { forkJoin, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export { TranslationService, TranslationConfig, TRANSLATION_SERVICE_CONFIG_TOKEN };

@Injectable({
  providedIn: 'root',
})
class TranslationService {
  private currentLocale = '';
  private userLocale = '';
  private defaultRealmLocale = '';

  constructor(
    private translate: TranslateService,
    @Optional() @Inject(TRANSLATION_SERVICE_CONFIG_TOKEN) private config?: TranslationConfig
  ) {
    if (!config?.allowedLocales || !config?.defaultLocale) {
      this.config = { allowedLocales: ['en', 'de'], defaultLocale: 'en' };
    }
  }

  // refactor
  loadMessages(): Observable<any> {
    this.determineCurrentLocale();
    return forkJoin([
      this.loadAppMessages(),
      this.loadDxMessages(this.currentLocale).pipe(tap((messages) => this.setDxLocale(this.currentLocale, messages))),
    ]); // loading only current locale. test if default is necessary
  }

  determineCurrentLocale(): void {
    const allowedLocales = this.getAllowedLocales();
    const userLocale = this.getUserLocale();
    const browserLocale = this.getBrowserLocale();
    const defaultRealmLocale = this.getDefaultRealmLocale();
    const defaultLocale = this.getDefaultLocale();
    let currentLocale: string;
    if (userLocale && allowedLocales.includes(userLocale)) {
      currentLocale = userLocale;
    } else if (browserLocale && allowedLocales.includes(browserLocale)) {
      currentLocale = browserLocale;
    } else if (defaultRealmLocale && allowedLocales.includes(defaultRealmLocale)) {
      currentLocale = defaultRealmLocale;
    } else {
      currentLocale = defaultLocale;
    }
    this.currentLocale = currentLocale;
  }

  getBrowserLocale(): string {
    return navigator.language.slice(0, 2);
  }

  setUserLocale(userLocale: string) {
    this.userLocale = userLocale;
  }

  getUserLocale(): string {
    return this.userLocale ? this.userLocale : '';
  }

  setDefaultRealmLocale(realmLocale: string) {
    this.defaultRealmLocale = realmLocale;
  }

  getDefaultRealmLocale(): string {
    return this.defaultRealmLocale ? this.defaultRealmLocale : '';
  }

  getCurrentLocale(): string {
    return this.currentLocale;
  }

  getAllowedLocales(): string[] {
    return this.config?.allowedLocales || ['en', 'de'];
  }

  getDefaultLocale(): string {
    return this.config?.defaultLocale || 'en';
  }

  loadAppMessages(): Observable<any> {
    return this.translate.use(this.currentLocale);
  }

  loadDxMessages(locale: string): Observable<any> {
    return from(import(`devextreme/localization/messages/${locale}.json`).then((m) => m.default));
  }

  private setDxLocale(currentLocale: string, messages?: any): void {
    loadMessages(messages);
    locale(currentLocale);
  }
}

type TranslationConfig = { allowedLocales?: string[]; defaultLocale?: string };

const TRANSLATION_SERVICE_CONFIG_TOKEN = new InjectionToken<TranslationConfig>('translationService.config');
