import * as Types from '../../sitos-x-api.types.g';

import { DocumentNode } from 'graphql';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /**
   * 
   * Represents a duration of time (see 'j' pattern https://nodatime.org/3.0.x/userguide/duration-patterns)
   * 
   * The format is:  -H:mm:ss.FFFFFFFFF
   * 
   * H - total hours (can be >=24)
   * mm - minutes (0-59)
   * ss - seconds (0-59)
   * FFFFFFFF - fractions of a second
   *
   */
  Duration: any;
  /**
   *
   * A UTC timestamp in ISO 8601 format (invariant culture). May includes fractions of seconds.
   *
   * Examples:
   *
   * - `2020-12-24T16:23:44,0003Z`
   *
   */
  Instant: any;
  /**
   *
   * Keys must conform to the regular expression `/^[A-Z][_A-Z0-9]*$/`
   *
   */
  Key: any;
  /**
   *
   * A local date without timezone, in ISO 8601 format (yyyy-mm-dd).
   *
   * Examples:
   *
   * - `2020-12-24`
   * - `2020-01-01`
   *
   */
  LocalDate: any;
  /**
   *
   * A local time without timezone or date, in 24 hour format.
   *
   * Examples:
   *
   * - `00:43:23`
   * - `07:55:30`
   * - `18:30:20`
   *
   */
  LocalTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /**
   *
   * UUID in all lowercase letters, with '-' separated for readability. Example: ac1ac624-4b13-4dfb-9cb3-0d84ba7e5767
   *
   */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Url: any;
  Uuid: any;
};

export type ActivateBadgesInput = {
  idOrKey: IdOrKeyInput;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type ActivateCatalogInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  showInCatalog: Scalars['Boolean'];
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type ActivateMediaInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  status: PublishStatus;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type AddUnitInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  name?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type AddUnitLearningManagersInput = {
  unitUuid?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AssignCourseGroupInput = {
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groups?: InputMaybe<UpsertLinksInput>;
};

export type AssignmentTokenDto = {
  __typename?: 'AssignmentTokenDTO';
  hasRoles: Scalars['Boolean'];
  payload?: Maybe<Scalars['String']>;
  person?: Maybe<PersonAssignmnetTokenDto>;
};

/** Assistant conversation token return object */
export type AssistantGql = {
  __typename?: 'AssistantGQL';
  url?: Maybe<Scalars['String']>;
};

export type AttemptGql = {
  __typename?: 'AttemptGQL';
  id: Scalars['ID'];
  key: Scalars['Key'];
  scoItem?: Maybe<Scorm12Item>;
  uuid: Scalars['UUID'];
};

/** Mutations creating and upserting authorization tokens and personalized tokens */
export type AuthorizationTokenMutation = {
  __typename?: 'AuthorizationTokenMutation';
  create: Scalars['String'];
  /** Creates personized token that will be send by email */
  createPersonizedToken: Scalars['String'];
  /** Convenience property, will always return '##' */
  id: Scalars['String'];
};


/** Mutations creating and upserting authorization tokens and personalized tokens */
export type AuthorizationTokenMutationCreateArgs = {
  input: CreateAuthorizationTokenInput;
};


/** Mutations creating and upserting authorization tokens and personalized tokens */
export type AuthorizationTokenMutationCreatePersonizedTokenArgs = {
  input: CreateAuthorizationPersonizedTokenInput;
};

/** Average Learning Time Widget */
export type AverageLearningTimeWidget = {
  __typename?: 'AverageLearningTimeWidget';
  friday?: Maybe<Day>;
  monday?: Maybe<Day>;
  saturday?: Maybe<Day>;
  sunday?: Maybe<Day>;
  thursday?: Maybe<Day>;
  tuesday?: Maybe<Day>;
  wednesday?: Maybe<Day>;
};

/** Badge Collection */
export type BadgeCollection = {
  __typename?: 'BadgeCollection';
  /** Badge present in course */
  courseHasBadge: Scalars['Boolean'];
  /** Course uuid */
  courseUuid: Scalars['String'];
  /** Course name */
  name: LangString;
};

export type BadgeDto = {
  __typename?: 'BadgeDto';
  description?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isSystemBadge: Scalars['Boolean'];
  series?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type BadgeNotificationsDto = {
  __typename?: 'BadgeNotificationsDto';
  badgeId: Scalars['Int'];
  badgeType?: Maybe<BadgeTypeEnum>;
  imageUrl?: Maybe<Scalars['String']>;
  notificationShown: Scalars['Boolean'];
  userId: Scalars['Int'];
  uuid?: Maybe<Scalars['UUID']>;
};

/** GraphQL mutation to upsert badges notifications */
export type BadgeNotificationsMutation = {
  __typename?: 'BadgeNotificationsMutation';
  updateBadgeNotifications?: Maybe<Badges_Notifications>;
};


/** GraphQL mutation to upsert badges notifications */
export type BadgeNotificationsMutationUpdateBadgeNotificationsArgs = {
  input?: InputMaybe<UpsertBadgeNotificationsInput>;
};

export enum BadgeTypeEnum {
  Badge = 'BADGE',
  Catalog = 'CATALOG',
  Completion = 'COMPLETION',
  Individual = 'INDIVIDUAL',
  Login = 'LOGIN'
}

/** The Badges */
export type Badges = {
  __typename?: 'Badges';
  /** Badge Type */
  badgeType: BadgeTypeEnum;
  /** Description in German */
  descriptionDe?: Maybe<Scalars['String']>;
  /** Description in English */
  descriptionEn?: Maybe<Scalars['String']>;
  /** Display Name */
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Image Url */
  imageUrl?: Maybe<Scalars['String']>;
  /** Is Active Badge */
  isActive: Scalars['Boolean'];
  /** Is Edit Badge */
  isEdited: Scalars['Boolean'];
  /** Is System Badge */
  isSystemBadge: Scalars['Boolean'];
  key: Scalars['Key'];
  /** German Series */
  seriesDe?: Maybe<Scalars['String']>;
  /** English Series */
  seriesEn?: Maybe<Scalars['String']>;
  /** System Badge Type */
  systemBadgeType?: Maybe<SystemBadgeTypesEnum>;
  /** Title in German */
  titleDe?: Maybe<Scalars['String']>;
  /** Title in English */
  titleEn?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  /** Primary */
  uuidPrimary?: Maybe<Scalars['String']>;
};

/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutation = {
  __typename?: 'BadgesMutation';
  activateDeactivateBadge: Badges;
  removeCourseBadge: Scalars['Boolean'];
  resetSystemBadge: Scalars['Boolean'];
  updateSystemBadge?: Maybe<Badges>;
  upsertCourseBadge?: Maybe<Badges>;
  upsertSystemBadge?: Maybe<Badges>;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationActivateDeactivateBadgeArgs = {
  input: ActivateBadgesInput;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationRemoveCourseBadgeArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationResetSystemBadgeArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationUpdateSystemBadgeArgs = {
  input: UpsertBadgeInput;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationUpsertCourseBadgeArgs = {
  input: UpsertBadgeInput;
};


/** GraphQL mutation to upsert, activate/deactivate, reset and delete badges  */
export type BadgesMutationUpsertSystemBadgeArgs = {
  input: UpsertBadgeInput;
};

/** The Badge Notifications */
export type Badges_Notifications = {
  __typename?: 'Badges_Notifications';
  /** Badge Id */
  badgeId: Scalars['Int'];
  id: Scalars['ID'];
  /** Image Url */
  imageUrl?: Maybe<Scalars['String']>;
  key: Scalars['Key'];
  /** Notification Shown */
  notificationShown: Scalars['Boolean'];
  /** User Id */
  userId: Scalars['Int'];
  uuid: Scalars['UUID'];
};

export type BookCourseInput = {
  catalog?: InputMaybe<Scalars['Boolean']>;
  course: IdOrKeyInput;
  dateOfReminder?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Instant']>;
  groupId?: InputMaybe<Scalars['Int']>;
  intervall?: InputMaybe<IntervallTypeEnum>;
  intervallEndDate?: InputMaybe<Scalars['Instant']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  roleLinks?: InputMaybe<Array<InputMaybe<UpsertRoleAssignmentInput>>>;
  startDate?: InputMaybe<Scalars['Instant']>;
};

export type BulkAcceptanceListInput = {
  userStatus?: InputMaybe<UserStatus>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkActivateCatalogInput = {
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showInCatalog: Scalars['Boolean'];
};

export type BulkActivateMediaInput = {
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status: PublishStatus;
};

export type BulkAnonymizeUserInput = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkDeleteCategoryInput = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkDeleteMediaInput = {
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type BulkDeleteScorm12ItemInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  itemUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkDeleteUserInput = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkInsertReuseNuggetInput = {
  courseId: Scalars['Int'];
  itemUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  parentId: Scalars['Int'];
};

export type BulkResendVerificationEmailInput = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkUpdateGroupStatusInput = {
  groupUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status: GroupStatus;
};

export type BulkUpdateMediaCategoriesInput = {
  categoriesUuid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkUpdateMediaGroupsInput = {
  activateMedia?: InputMaybe<Scalars['Boolean']>;
  completionNotification: Scalars['Boolean'];
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dateOfReminder?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Instant']>;
  groups?: InputMaybe<UpsertLinksInput>;
  intervall?: InputMaybe<IntervallTypeEnum>;
  intervallEndDate?: InputMaybe<Scalars['Instant']>;
  newCourseNotification: Scalars['Boolean'];
  roleLinks?: InputMaybe<Array<InputMaybe<UpsertRoleAssignmentInput>>>;
  startDate?: InputMaybe<Scalars['Instant']>;
};

export type BulkUpdateUserGroupsInput = {
  groups?: InputMaybe<UpsertLinksInput>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkUpdateUserStatusInput = {
  status: UserStatus;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CalendarData = {
  __typename?: 'CalendarData';
  color?: Maybe<Scalars['String']>;
  courseTitle?: Maybe<LangString>;
  endDate?: Maybe<Scalars['Instant']>;
  startDate?: Maybe<Scalars['Instant']>;
};

export enum CatalogSortingItems {
  LearningTimeLongShort = 'LEARNING_TIME_LONG_SHORT',
  LearningTimeShortLong = 'LEARNING_TIME_SHORT_LONG',
  ReleaseDateNewOld = 'RELEASE_DATE_NEW_OLD',
  ReleaseDateOldNew = 'RELEASE_DATE_OLD_NEW',
  TitleAz = 'TITLE_AZ',
  TitleZa = 'TITLE_ZA'
}

/** Catelogue favourite for user */
export type CatalogueFavourite = {
  __typename?: 'CatalogueFavourite';
  /** Course */
  courseName: LangString;
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** User */
  userFullName: Scalars['String'];
  uuid: Scalars['UUID'];
};

/** Mutation for updating/upserting and removing Catalog Favorites */
export type CatalogueFavouriteMutation = {
  __typename?: 'CatalogueFavouriteMutation';
  upsert: CatalogueFavourite;
};


/** Mutation for updating/upserting and removing Catalog Favorites */
export type CatalogueFavouriteMutationUpsertArgs = {
  input: FavouriteCatelogueItemInput;
};

/** A category */
export type Category = {
  __typename?: 'Category';
  /** Assigned media count on the categories */
  assignedMediaCount: Scalars['Int'];
  /** Categories assigned to the course */
  categories?: Maybe<Array<Maybe<Scorm12Course>>>;
  /** Defines description of the category */
  description: LangString;
  /** Categories assigned to the enrolled course */
  enrolledCategories?: Maybe<Array<Maybe<Scorm12EnrolledCourse>>>;
  /** Icon URL */
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** Defines name of the category */
  name: LangString;
  uuid: Scalars['UUID'];
};

export type CategoryDto = {
  __typename?: 'CategoryDto';
  courseCount: Scalars['Int'];
  description?: Maybe<LangString>;
  enrolledCategories?: Maybe<Array<Maybe<Scorm12EnrolledCourseDto>>>;
  enrolledCategoriesCount: Scalars['Int'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Array<Scalars['Byte']>>;
  imageType?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<LangString>;
  totalCategories: Scalars['Int'];
  uuid: Scalars['UUID'];
};

/** GraphQL mutation to update, remove, or bulk remove a category */
export type CategoryMutation = {
  __typename?: 'CategoryMutation';
  bulkRemove: Scalars['Boolean'];
  remove: Scalars['Boolean'];
  reorderCategoryMedias?: Maybe<Category>;
  upsert: Category;
};


/** GraphQL mutation to update, remove, or bulk remove a category */
export type CategoryMutationBulkRemoveArgs = {
  input: BulkDeleteCategoryInput;
};


/** GraphQL mutation to update, remove, or bulk remove a category */
export type CategoryMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation to update, remove, or bulk remove a category */
export type CategoryMutationReorderCategoryMediasArgs = {
  input: UpdateCategoryMediasOrderInput;
};


/** GraphQL mutation to update, remove, or bulk remove a category */
export type CategoryMutationUpsertArgs = {
  input: UpsertCategoryInput;
};

/** A category */
export type CategoryRefactor = {
  __typename?: 'CategoryRefactor';
  /** Assigned media count on the categories */
  assignedMediaCount: Scalars['Int'];
  /** Defines key of the category */
  key: Scalars['String'];
  /** Defines name of the category */
  name: LangString;
  /** Defines uuid of the category */
  uuid: Scalars['UUID'];
};

/** Generate Base64 string for certificate */
export type CertGenerateGql = {
  __typename?: 'CertGenerateGQL';
  base64String?: Maybe<Scalars['String']>;
};

/** Generate Base64 string for certificate preview */
export type CertPreviewGql = {
  __typename?: 'CertPreviewGQL';
  htmlText?: Maybe<Scalars['String']>;
};

/** A certificate */
export type Certificate = {
  __typename?: 'Certificate';
  /** Completion date */
  completionDate?: Maybe<Scalars['String']>;
  /** Course name assigned certificate */
  courseName?: Maybe<LangString>;
  /** Course uuid assigned certificate */
  courseUuid: Scalars['UUID'];
  /** German heading */
  headingDe?: Maybe<Scalars['String']>;
  /** English heading */
  headingEn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** Languages */
  languages: Scalars['Int'];
  /** Logo URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** Media items count */
  mediaItemsCount: Scalars['Int'];
  systemLanguage?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** German text */
  textDe?: Maybe<Scalars['String']>;
  /** English text */
  textEn?: Maybe<Scalars['String']>;
  /** English text */
  title?: Maybe<Scalars['String']>;
  /** Defines title of certificate in German */
  titleDe: Scalars['String'];
  /** Defines title of certificate in English */
  titleEn: Scalars['String'];
  /** Type */
  type: CertificateTypeEnum;
  uuid: Scalars['UUID'];
};

export type CertificateDownloadInput = {
  courseUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type CertificateDto = {
  __typename?: 'CertificateDto';
  createdDate: Scalars['Instant'];
  deletedDate?: Maybe<Scalars['Instant']>;
  headingDe?: Maybe<Scalars['String']>;
  headingEn?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  textDe?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
  type: CertificateTypeEnum;
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
};

export type CertificatePreviewInput = {
  heading?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type: CertificateTypeEnum;
};

export enum CertificateTypeEnum {
  Template1 = 'TEMPLATE1',
  Template3 = 'TEMPLATE3'
}

/** GraphQL mutation to update, upsert, remove or duplicate a certificate */
export type CertificatesMutation = {
  __typename?: 'CertificatesMutation';
  duplicate?: Maybe<Certificate>;
  remove: Scalars['Boolean'];
  upsert: Certificate;
};


/** GraphQL mutation to update, upsert, remove or duplicate a certificate */
export type CertificatesMutationDuplicateArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation to update, upsert, remove or duplicate a certificate */
export type CertificatesMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation to update, upsert, remove or duplicate a certificate */
export type CertificatesMutationUpsertArgs = {
  input: UpsertCertificateInput;
};

export type CommitScorm12AttemptInput = {
  entry?: InputMaybe<Scorm12_Entry>;
  idOrKey: IdOrKeyInput;
  lessonLocation?: InputMaybe<Scalars['String']>;
  lessonStatus?: InputMaybe<Scorm12_LessonStatus>;
  masteryScore?: InputMaybe<Scalars['Float']>;
  scoreMax?: InputMaybe<Scalars['Float']>;
  scoreMin?: InputMaybe<Scalars['Float']>;
  scoreRaw?: InputMaybe<Scalars['Float']>;
  sessionApiKey: Scalars['String'];
  suspendData?: InputMaybe<Scalars['String']>;
  timeSpent?: InputMaybe<Scalars['Duration']>;
};

export type CompletionDay = {
  __typename?: 'CompletionDay';
  completions: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<CompletionDetails>>>;
};

export type CompletionDetails = {
  __typename?: 'CompletionDetails';
  courseId: Scalars['UUID'];
  courseName?: Maybe<LangString>;
  users?: Maybe<Array<Maybe<CompletionUsers>>>;
  usersCount: Scalars['Int'];
};

export type CompletionUsers = {
  __typename?: 'CompletionUsers';
  name?: Maybe<Scalars['String']>;
};

/** Config object */
export type Config = {
  __typename?: 'Config';
  /** Logo URL */
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Key */
  key: Scalars['String'];
  /** Uuid */
  uuid: Scalars['String'];
};

/** Mutation for upserting configuration data */
export type ConfigsMutation = {
  __typename?: 'ConfigsMutation';
  enableBadge: CourseUploadAccessibility;
  enableCourseUpload: CourseUploadAccessibility;
  enableZulipChat: CourseUploadAccessibility;
  switchCalendarWidget: CourseUploadAccessibility;
  switchChatbot: CourseUploadAccessibility;
  switchEvaluation: CourseUploadAccessibility;
  switchMailingStatus: CourseUploadAccessibility;
  switchParent: CourseUploadAccessibility;
  switchRecommendation: CourseUploadAccessibility;
  upsert: Config;
};


/** Mutation for upserting configuration data */
export type ConfigsMutationEnableBadgeArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationEnableCourseUploadArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationEnableZulipChatArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchCalendarWidgetArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchChatbotArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchEvaluationArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchMailingStatusArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchParentArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationSwitchRecommendationArgs = {
  input: Scalars['Boolean'];
};


/** Mutation for upserting configuration data */
export type ConfigsMutationUpsertArgs = {
  input: UpsertConfigInput;
};

export enum CourseCompletionStatus {
  All = 'ALL',
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export type CourseCompletionsGql = {
  __typename?: 'CourseCompletionsGQL';
  friday?: Maybe<CompletionDay>;
  monday?: Maybe<CompletionDay>;
  saturday?: Maybe<CompletionDay>;
  sunday?: Maybe<CompletionDay>;
  thursday?: Maybe<CompletionDay>;
  tuesday?: Maybe<CompletionDay>;
  wednesday?: Maybe<CompletionDay>;
};

export type CourseConnectionGql = {
  __typename?: 'CourseConnectionGQL';
  edges: Array<CourseEdgeGql>;
  nodes: Array<Scorm12Course>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CourseConnectionGqlFilterInput = {
  and?: InputMaybe<Array<CourseConnectionGqlFilterInput>>;
  or?: InputMaybe<Array<CourseConnectionGqlFilterInput>>;
  totalCount?: InputMaybe<IntOperationFilterInput>;
};

export type CourseConnectionGqlSortInput = {
  totalCount?: InputMaybe<SortEnumType>;
};

export type CourseEdgeGql = {
  __typename?: 'CourseEdgeGQL';
  cursor: Scalars['String'];
  node: Scorm12Course;
};

export type CourseEvaluationDto = {
  __typename?: 'CourseEvaluationDto';
  comment?: Maybe<Scalars['String']>;
  course?: Maybe<Scorm12CourseDto>;
  createdAt?: Maybe<Scalars['Instant']>;
  id: Scalars['Int'];
  isAnonym: Scalars['Boolean'];
  metadata?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
  user?: Maybe<UserDto>;
  uuid: Scalars['UUID'];
};

export type CourseEvaluationGql = {
  __typename?: 'CourseEvaluationGQL';
  /** Evaluation comment */
  comment: Scalars['String'];
  /** Evaluation CreatedAt */
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  /** Evaluation IsAnonym */
  isAnonym: Scalars['Boolean'];
  key: Scalars['Key'];
  /** Evaluation Rating */
  rating: Scalars['Float'];
  /** Evaluation User */
  user: User;
  uuid: Scalars['UUID'];
};

export type CourseEvaluationInput = {
  comment?: InputMaybe<Scalars['String']>;
  courseUuid?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  isAnonym: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  rating: Scalars['Float'];
  uuid?: InputMaybe<Scalars['UUID']>;
};

/** GraphQL mutation for upserting course evaluation */
export type CourseEvaluationMutation = {
  __typename?: 'CourseEvaluationMutation';
  remove: Scalars['Boolean'];
  updateEvaluation: CourseEvaluationGql;
  upsert: CourseEvaluationGql;
};


/** GraphQL mutation for upserting course evaluation */
export type CourseEvaluationMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation for upserting course evaluation */
export type CourseEvaluationMutationUpdateEvaluationArgs = {
  input: UpdateCourseEvaluationInput;
};


/** GraphQL mutation for upserting course evaluation */
export type CourseEvaluationMutationUpsertArgs = {
  input: CourseEvaluationInput;
};

/** GraphQL mutation for updateing and upserting favorite courses */
export type CourseFavouriteMutation = {
  __typename?: 'CourseFavouriteMutation';
  upsert: Scorm12EnrolledCourse;
};


/** GraphQL mutation for updateing and upserting favorite courses */
export type CourseFavouriteMutationUpsertArgs = {
  input: UpdateCourseFavouriteInput;
};

/** Course history and last visited Widget */
export type CourseHistoryAndLastVisitedWidget = {
  __typename?: 'CourseHistoryAndLastVisitedWidget';
  /** Last accessed course date */
  completionDate?: Maybe<Scalars['String']>;
  /** Course uuid */
  courseUuid: Scalars['String'];
  /** Enrolled course uuid */
  enrolledCourseUuid: Scalars['String'];
  /** Course image */
  image?: Maybe<Scalars['String']>;
  /** Course name */
  name: LangString;
};

/** Course Upload Accessibility object */
export type CourseUploadAccessibility = {
  __typename?: 'CourseUploadAccessibility';
  id: Scalars['ID'];
  /** IsEnabled */
  isEnabled: Scalars['Boolean'];
  key: Scalars['Key'];
  uuid: Scalars['UUID'];
};

export enum CourseUploadStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Running = 'RUNNING'
}

export type CourseVersionGql = {
  __typename?: 'CourseVersionGQL';
  /** Returns if the learning path draft can be deleted */
  canBeDeleted: Scalars['Boolean'];
  /** Returns number of nuggets */
  countOfNuggets: Scalars['Int'];
  /** Defines date of course creation */
  dateOfCreation: Scalars['Instant'];
  id: Scalars['ID'];
  /** Items added count */
  itemsAdded: Scalars['Int'];
  /** Items removed count */
  itemsRemoved: Scalars['Int'];
  key: Scalars['Key'];
  /** Learning path status */
  status?: Maybe<LearningPathStatusEnum>;
  /** Defines date of course update */
  updatedDate?: Maybe<Scalars['Instant']>;
  uuid: Scalars['UUID'];
  /** Version number */
  version: Scalars['String'];
};

export type CreateAuthorizationPersonizedTokenInput = {
  /** Token expiration date */
  expirationDate?: InputMaybe<Scalars['LocalDate']>;
  /** Token expiration time */
  expirationTime?: InputMaybe<Scalars['LocalTime']>;
  personLink?: InputMaybe<IdOrKeyInput>;
};

export type CreateAuthorizationTokenInput = {
  /** Token expiration date */
  expirationDate?: InputMaybe<Scalars['LocalDate']>;
  /** Token expiration time */
  expirationTime?: InputMaybe<Scalars['LocalTime']>;
  /** List of OrgGroup names */
  orgGroups?: InputMaybe<Array<InputMaybe<UpsertOrgGroupsInput>>>;
  /** List of roles names */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Day = {
  __typename?: 'Day';
  averageLearningTimeNumber: Scalars['Decimal'];
  averageLearningTimeString?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  onlineUsers: Scalars['Int'];
};

export type DeactivateMediaInput = {
  courseUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupUuid?: InputMaybe<Scalars['String']>;
};

export type DeleteMediaInput = {
  idOrKey: IdOrKeyInput;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type DeleteUnitLearningManagerInput = {
  unitId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum EmailTemplateEnum {
  BookNewCourse = 'BOOK_NEW_COURSE',
  CourseCompleted = 'COURSE_COMPLETED',
  MandatoryNotification = 'MANDATORY_NOTIFICATION',
  NewCoursesBulkAssignment = 'NEW_COURSES_BULK_ASSIGNMENT',
  NonCompletionReportNotification = 'NON_COMPLETION_REPORT_NOTIFICATION',
  RecurringNotification = 'RECURRING_NOTIFICATION',
  ReminderNotification = 'REMINDER_NOTIFICATION',
  ResetPassword = 'RESET_PASSWORD',
  TemporaryNotification = 'TEMPORARY_NOTIFICATION',
  UserInvitation = 'USER_INVITATION'
}

export type EnrollStudentInput = {
  course: IdOrKeyInput;
  student: IdOrKeyInput;
};

/** GraphQL mutation for updating enrolled course entity */
export type EnrolledCourseMutation = {
  __typename?: 'EnrolledCourseMutation';
  completeCourse: Scalars['Boolean'];
  completeCourseManually: Scalars['Boolean'];
  resetStatistic: Scalars['Boolean'];
  skipCelebrate: Scalars['Boolean'];
};


/** GraphQL mutation for updating enrolled course entity */
export type EnrolledCourseMutationCompleteCourseArgs = {
  enrolledCourseIdentifier: IdOrKeyInput;
};


/** GraphQL mutation for updating enrolled course entity */
export type EnrolledCourseMutationCompleteCourseManuallyArgs = {
  enrolledCourseIdentifier: IdOrKeyInput;
  enrolledUserIdentifier: IdOrKeyInput;
};


/** GraphQL mutation for updating enrolled course entity */
export type EnrolledCourseMutationResetStatisticArgs = {
  enrolledCourseIdentifier: IdOrKeyInput;
  enrolledUserIdentifier: IdOrKeyInput;
};


/** GraphQL mutation for updating enrolled course entity */
export type EnrolledCourseMutationSkipCelebrateArgs = {
  enrolledCourseIdentifier: IdOrKeyInput;
};

export type EnrolledItemFilterInput = {
  manifestIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ExportBase64Gql = {
  __typename?: 'ExportBase64GQL';
  base64String?: Maybe<Scalars['String']>;
};

export type ExportRecurringInput = {
  course: IdOrKeyInput;
  user: IdOrKeyInput;
};

export type FavouriteCatelogueItemInput = {
  course: IdOrKeyInput;
  favourite: Scalars['Boolean'];
};

export type FileInfoResult = {
  __typename?: 'FileInfoResult';
  fileName?: Maybe<Scalars['String']>;
  fileNameSubTitle?: Maybe<Scalars['String']>;
  fileSize: Scalars['Long'];
  loopToPlay?: Maybe<Scalars['Boolean']>;
};

export type FileInfoReturn = {
  __typename?: 'FileInfoReturn';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  learningTime?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** A group */
export type Group = {
  __typename?: 'Group';
  /** Assigned media */
  assignedMedia: Scalars['Int'];
  /** Assigned media details */
  assignedMediaDetails?: Maybe<MediaDetailsStatisticsGql>;
  /** Media assigned to the group */
  assignedMediaItems: Array<Maybe<Scorm12Course>>;
  /** Assigned unit id */
  assignedUnitId: Scalars['Int'];
  /** Users assigned to the group */
  assignedUserItems: Array<Maybe<User>>;
  /** Assigned users */
  assignedUsers: Scalars['Int'];
  /** Group status */
  groupStatus: GroupStatus;
  id: Scalars['ID'];
  /** Is general group */
  isGeneral: Scalars['Boolean'];
  key: Scalars['Key'];
  /** Defines name of the group */
  name: LangString;
  /** Associated Role to a Group. */
  roles: Array<Maybe<Role>>;
  uuid: Scalars['UUID'];
};

/** GraphQl mutation for updating and uspserting Groups */
export type GroupMutation = {
  __typename?: 'GroupMutation';
  bulkUpdateGroupStatus: Array<Maybe<Group>>;
  updateGroupStatus: Group;
  upsert: Group;
};


/** GraphQl mutation for updating and uspserting Groups */
export type GroupMutationBulkUpdateGroupStatusArgs = {
  input: BulkUpdateGroupStatusInput;
};


/** GraphQl mutation for updating and uspserting Groups */
export type GroupMutationUpdateGroupStatusArgs = {
  input: UpdateGroupStatusInput;
};


/** GraphQl mutation for updating and uspserting Groups */
export type GroupMutationUpsertArgs = {
  input: UpsertGroupInput;
};

/** A group */
export type GroupRefactored = {
  __typename?: 'GroupRefactored';
  /** Assigned media */
  assignedMedia: Scalars['Int'];
  /** Assigned users */
  assignedUsers: Scalars['Int'];
  /** Status */
  groupStatus: GroupStatus;
  /** Id */
  id: Scalars['Int'];
  /** Is general group */
  isGeneral: Scalars['Boolean'];
  /** Key */
  key: Scalars['String'];
  /** Defines name of the group */
  name: LangString;
  /** Uuid */
  uuid: Scalars['UUID'];
};

export enum GroupStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED'
}

export type GroupsFiltersInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  maxMediaCount?: InputMaybe<Scalars['Int']>;
  maxUsersCount?: InputMaybe<Scalars['Int']>;
  minMediaCount?: InputMaybe<Scalars['Int']>;
  minUsersCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type HelpAdminInput = {
  gtcTextDe?: InputMaybe<Scalars['String']>;
  gtcTextEn?: InputMaybe<Scalars['String']>;
  imprintTextDe?: InputMaybe<Scalars['String']>;
  imprintTextEn?: InputMaybe<Scalars['String']>;
  introLink?: InputMaybe<Scalars['String']>;
  introTextDe?: InputMaybe<Scalars['String']>;
  introTextEn?: InputMaybe<Scalars['String']>;
  privacyTextDe?: InputMaybe<Scalars['String']>;
  privacyTextEn?: InputMaybe<Scalars['String']>;
};

/** GraphQL mutation for updating and upserting HelpAdmin data */
export type HelpAdminMutation = {
  __typename?: 'HelpAdminMutation';
  upsert: HelpAdmin_Output;
};


/** GraphQL mutation for updating and upserting HelpAdmin data */
export type HelpAdminMutationUpsertArgs = {
  input: HelpAdminInput;
};

/** Help Admin output object */
export type HelpAdmin_Output = {
  __typename?: 'HelpAdmin_output';
  /** Defines gtc */
  gtcText: Scalars['String'];
  gtcTextDe?: Maybe<Scalars['String']>;
  gtcTextEn?: Maybe<Scalars['String']>;
  /** Defines imprint */
  imprintText: Scalars['String'];
  imprintTextDe?: Maybe<Scalars['String']>;
  imprintTextEn?: Maybe<Scalars['String']>;
  introLink?: Maybe<Scalars['String']>;
  /** Defines introduction of help */
  introText: Scalars['String'];
  introTextDe?: Maybe<Scalars['String']>;
  introTextEn?: Maybe<Scalars['String']>;
  /** Defines privacy */
  privacyText: Scalars['String'];
  privacyTextDe?: Maybe<Scalars['String']>;
  privacyTextEn?: Maybe<Scalars['String']>;
  systemLanguage?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum HttpStatusCode {
  Accepted = 'ACCEPTED',
  AlreadyReported = 'ALREADY_REPORTED',
  Ambiguous = 'AMBIGUOUS',
  BadGateway = 'BAD_GATEWAY',
  BadRequest = 'BAD_REQUEST',
  Conflict = 'CONFLICT',
  Continue = 'CONTINUE',
  Created = 'CREATED',
  EarlyHints = 'EARLY_HINTS',
  ExpectationFailed = 'EXPECTATION_FAILED',
  FailedDependency = 'FAILED_DEPENDENCY',
  Forbidden = 'FORBIDDEN',
  GatewayTimeout = 'GATEWAY_TIMEOUT',
  Gone = 'GONE',
  HttpVersionNotSupported = 'HTTP_VERSION_NOT_SUPPORTED',
  ImUsed = 'IM_USED',
  InsufficientStorage = 'INSUFFICIENT_STORAGE',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  LengthRequired = 'LENGTH_REQUIRED',
  Locked = 'LOCKED',
  LoopDetected = 'LOOP_DETECTED',
  MethodNotAllowed = 'METHOD_NOT_ALLOWED',
  MisdirectedRequest = 'MISDIRECTED_REQUEST',
  Moved = 'MOVED',
  MultiStatus = 'MULTI_STATUS',
  NetworkAuthenticationRequired = 'NETWORK_AUTHENTICATION_REQUIRED',
  NonAuthoritativeInformation = 'NON_AUTHORITATIVE_INFORMATION',
  NotAcceptable = 'NOT_ACCEPTABLE',
  NotExtended = 'NOT_EXTENDED',
  NotFound = 'NOT_FOUND',
  NotImplemented = 'NOT_IMPLEMENTED',
  NotModified = 'NOT_MODIFIED',
  NoContent = 'NO_CONTENT',
  Ok = 'OK',
  PartialContent = 'PARTIAL_CONTENT',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PermanentRedirect = 'PERMANENT_REDIRECT',
  PreconditionFailed = 'PRECONDITION_FAILED',
  PreconditionRequired = 'PRECONDITION_REQUIRED',
  Processing = 'PROCESSING',
  ProxyAuthenticationRequired = 'PROXY_AUTHENTICATION_REQUIRED',
  Redirect = 'REDIRECT',
  RedirectMethod = 'REDIRECT_METHOD',
  RequestedRangeNotSatisfiable = 'REQUESTED_RANGE_NOT_SATISFIABLE',
  RequestEntityTooLarge = 'REQUEST_ENTITY_TOO_LARGE',
  RequestHeaderFieldsTooLarge = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
  RequestTimeout = 'REQUEST_TIMEOUT',
  RequestUriTooLong = 'REQUEST_URI_TOO_LONG',
  ResetContent = 'RESET_CONTENT',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  SwitchingProtocols = 'SWITCHING_PROTOCOLS',
  TemporaryRedirect = 'TEMPORARY_REDIRECT',
  TooManyRequests = 'TOO_MANY_REQUESTS',
  Unauthorized = 'UNAUTHORIZED',
  UnavailableForLegalReasons = 'UNAVAILABLE_FOR_LEGAL_REASONS',
  UnprocessableEntity = 'UNPROCESSABLE_ENTITY',
  UnsupportedMediaType = 'UNSUPPORTED_MEDIA_TYPE',
  Unused = 'UNUSED',
  UpgradeRequired = 'UPGRADE_REQUIRED',
  UseProxy = 'USE_PROXY',
  VariantAlsoNegotiates = 'VARIANT_ALSO_NEGOTIATES'
}

/** The Icon */
export type Icon = {
  __typename?: 'Icon';
  id: Scalars['ID'];
  /** Image url */
  imageUrl?: Maybe<Scalars['String']>;
  /** Is Active */
  isActive: Scalars['Boolean'];
  key: Scalars['Key'];
  /** Title in German */
  titleDe?: Maybe<Scalars['String']>;
  /** Title in English */
  titleEn?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

/** GraphQl mutation for updating/upsert icons */
export type IconMutation = {
  __typename?: 'IconMutation';
  upsertIcons?: Maybe<Icon>;
};


/** GraphQl mutation for updating/upsert icons */
export type IconMutationUpsertIconsArgs = {
  input: UpsertIconInput;
};

/** You can specify either a id, key or uuid, or let the system guess your value. Will be changed to `oneOf` type once it is in the GraphQL Spec */
export type IdOrKeyInput = {
  guess?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['Key']>;
  /**
   * 
   * The key will be normalized to a valid key - it will be made uppercase, and non-letters will be 
   * converted to underscore. Consecutive, trailing and leading underscores will be ommitted.
   *             
   * Example: `john.doe@bitmedia.at` => `JOHN_DOE_BITMEDIA_AT`
   */
  laxKey?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['Uuid']>;
};

export type ImportLogsFiltersInput = {
  fileName?: InputMaybe<Scalars['String']>;
  fileUrl?: InputMaybe<Scalars['String']>;
  maxErrorsCount?: InputMaybe<Scalars['Int']>;
  maxImportDate?: InputMaybe<Scalars['Instant']>;
  maxImportedUsersCount?: InputMaybe<Scalars['Int']>;
  minErrorsCount?: InputMaybe<Scalars['Int']>;
  minImportDate?: InputMaybe<Scalars['Instant']>;
  minImportedUsersCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ImportStatusEnum>;
};

export enum ImportStatusEnum {
  Error = 'ERROR',
  Fail = 'FAIL',
  Success = 'SUCCESS'
}

export type ImportUserLogsInput = {
  errors: Scalars['Int'];
  file?: InputMaybe<Scalars['Upload']>;
  fileName?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  importedUsers: Scalars['Int'];
  key?: InputMaybe<Scalars['Key']>;
  overwrite: Scalars['Boolean'];
  overwritten?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

/** Import users by uploading xlsx/csv file with user data */
export type ImportUsers = {
  __typename?: 'ImportUsers';
  /** Erros */
  errors: Scalars['Int'];
  /** Filename */
  fileName: Scalars['String'];
  /** File URL */
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Import date */
  importDate: Scalars['String'];
  key: Scalars['Key'];
  /** Number of users */
  numberOfUsers: Scalars['Int'];
  /** Status */
  status: ImportStatusEnum;
  uuid: Scalars['UUID'];
};

/** Import users by uploading xlsx/csv file with user data */
export type ImportUsersRefactor = {
  __typename?: 'ImportUsersRefactor';
  /** Erros */
  errors: Scalars['Int'];
  /** Filename */
  fileName: Scalars['String'];
  /** File URL */
  fileUrl: Scalars['String'];
  /** Import date */
  importDate: Scalars['String'];
  /** Number of users */
  numberOfUsers: Scalars['Int'];
  /** Status */
  status: ImportStatusEnum;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export enum IntervallTypeEnum {
  Annual = 'ANNUAL',
  Biannual = 'BIANNUAL',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quaterly = 'QUATERLY',
  Weekly = 'WEEKLY'
}

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/**
 *
 * A localized or translatable text. To get the Neutral value (untranslated), a shortcut to 'neutral' is available.
 * To get the text that matches the current users preferences, use 'value'.
 *
 */
export type LangString = {
  __typename?: 'LangString';
  id: Scalars['ID'];
  /** The neutral (untranslated) text that is used when no language specific override was found */
  neutral: Scalars['String'];
  /** The localized text matching the users (or systems) preferences */
  value: Scalars['String'];
  /** Localized values */
  values: Array<LangStringValue>;
};

export type LangStringValue = {
  __typename?: 'LangStringValue';
  id: Scalars['ID'];
  /**
   *
   * The ISO 639 code.
   *
   * Example:
   * * de-AT
   * * en
   * * x-none
   *
   * :::note
   *
   * `x-none` is the default value
   *
   * :::
   *
   */
  locale: Scalars['String'];
  /** The localized text */
  value: Scalars['String'];
};

/** Latest catalogue */
export type LatestCatalogueWidget = {
  __typename?: 'LatestCatalogueWidget';
  /** Course uuid */
  courseUuid: Scalars['String'];
  /** Create date */
  createDate?: Maybe<Scalars['String']>;
  /** Course name */
  name: LangString;
};

export type LaunchScoResult = {
  __typename?: 'LaunchScoResult';
  attempt: Scorm12EnrolledItem;
  launchUri: Scalars['String'];
  session: Scalars['UUID'];
  sessionApiKey: Scalars['String'];
};

export type LearningPathDetail = {
  __typename?: 'LearningPathDetail';
  chapter?: Maybe<LangString>;
  id?: Maybe<Scalars['Int']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  launchUri?: Maybe<Scalars['String']>;
  lessonTitle?: Maybe<LangString>;
  parentId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export enum LearningPathStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum LocalizationEnum {
  De = 'DE',
  En = 'EN'
}

/** GraphQL mutation for updating and upserting Mail Templates */
export type MailTemplatesMutation = {
  __typename?: 'MailTemplatesMutation';
  upsert: Mailtemplate;
};


/** GraphQL mutation for updating and upserting Mail Templates */
export type MailTemplatesMutationUpsertArgs = {
  input: UpsertMailTemplateInput;
};

/** A mail template */
export type Mailtemplate = {
  __typename?: 'Mailtemplate';
  /** German additional information */
  additionalInformationDe?: Maybe<Scalars['String']>;
  /** English additional information */
  additionalInformationEn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** German main text */
  mainTextDe?: Maybe<Scalars['String']>;
  /** English main text */
  mainTextEn?: Maybe<Scalars['String']>;
  /** Metadata */
  metadata?: Maybe<Scalars['String']>;
  /** Defines name of the mail template */
  name: LangString;
  /** Defines name of the mail template */
  name2: Scalars['String'];
  /** German subject */
  subjectDe?: Maybe<Scalars['String']>;
  /** English subject */
  subjectEn?: Maybe<Scalars['String']>;
  systemLanguage?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Type */
  type: EmailTemplateEnum;
  uuid: Scalars['UUID'];
};

/** Detailed statistics of a media */
export type MediaDetailsStatisticsGql = {
  __typename?: 'MediaDetailsStatisticsGQL';
  /** Active */
  active: Scalars['String'];
  /** User Active Lessons */
  activeLessons?: Maybe<Array<Maybe<UserStaticsLesson>>>;
  /** Are courses notifications on */
  areNotificationsOn: Scalars['Boolean'];
  /** Calls */
  calls: Scalars['Int'];
  /** Catalog booked */
  catalog: Scalars['Boolean'];
  /** Completion date */
  completion: Scalars['String'];
  /** Contact notified */
  contactNotified?: Maybe<Array<Maybe<Role>>>;
  /** Contact roles */
  contactRoles: Scalars['String'];
  /** Course title */
  courseTitle: LangString;
  /** Uuid */
  courseUuid: Scalars['String'];
  /** Current result */
  currentResult: Scalars['Float'];
  /** Date of reminder */
  dateOfReminder: Scalars['Int'];
  /** Email of the user */
  email: Scalars['String'];
  /** End date */
  endDate: Scalars['String'];
  /** Enrollment date */
  enrollment: Scalars['String'];
  /** Firstname of the user */
  firstName: Scalars['String'];
  /** Group assigned */
  group?: Maybe<Group>;
  /** Enroll history calls */
  historyCalls: Scalars['Int'];
  /** Enroll history status */
  historyStatus?: Maybe<Scalars['String']>;
  /** Course id */
  id: Scalars['String'];
  /** Intervall */
  intervall: Scalars['String'];
  /** Intervall End date */
  intervallEndDate: Scalars['String'];
  /** Is user anonymized */
  isAnonymized: Scalars['Boolean'];
  /** Lastname of the user */
  lastName: Scalars['String'];
  /** Assigned learners */
  learners?: Maybe<Array<Maybe<User>>>;
  /** Start date */
  startDate: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Group assigned */
  unit?: Maybe<Unit>;
  /** Uuid */
  uuid: Scalars['String'];
};

export type MediaFiltersInput = {
  hasCertificate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isActiveForCatalog?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  maxGroups?: InputMaybe<Scalars['Int']>;
  maxTopics?: InputMaybe<Scalars['Int']>;
  minGroups?: InputMaybe<Scalars['Int']>;
  minTopics?: InputMaybe<Scalars['Int']>;
  selectedCategory?: InputMaybe<Scalars['UUID']>;
  title?: InputMaybe<Scalars['String']>;
};

/** A course */
export type MediaGqlRefactored = {
  __typename?: 'MediaGQLRefactored';
  /** Active for catalog */
  activeForCatalog: Scalars['Boolean'];
  /** Assigned categories */
  assignedCategories: Scalars['Int'];
  /** Assigned categories */
  assignedCategoriesIds: Array<Scalars['UUID']>;
  /** Assigned groups */
  assignedGroups: Scalars['Int'];
  /** Certificate */
  certificate: Scalars['Boolean'];
  creator?: Maybe<Scalars['String']>;
  /** Id */
  id: Scalars['String'];
  /** Catalog */
  isActive: Scalars['Boolean'];
  isLearningPath?: Maybe<Scalars['Boolean']>;
  /** Course item identifier */
  itemIdentifier: Scalars['String'];
  /** Language */
  language: Scalars['String'];
  /** Media type */
  mediaType: Scalars['String'];
  scos?: Maybe<Array<Maybe<LearningPathDetail>>>;
  /** Defines name of the course */
  title: LangString;
  /** Type */
  type: Scalars['String'];
  /** Uuid */
  uuid: Scalars['UUID'];
  /** Current version */
  version: Scalars['String'];
};

export type MediaStatisticsFiltersInput = {
  creationDateMax?: InputMaybe<Scalars['Instant']>;
  creationDateMin?: InputMaybe<Scalars['Instant']>;
  id?: InputMaybe<Scalars['String']>;
  lastCourseCallDateMax?: InputMaybe<Scalars['Instant']>;
  lastCourseCallDateMin?: InputMaybe<Scalars['Instant']>;
  maxAssignmentsCount?: InputMaybe<Scalars['Int']>;
  maxCallsCount?: InputMaybe<Scalars['Int']>;
  maxCompletionsCount?: InputMaybe<Scalars['Int']>;
  minAssignmentsCount?: InputMaybe<Scalars['Int']>;
  minCallsCount?: InputMaybe<Scalars['Int']>;
  minCompletionsCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Statistics of a Media */
export type MediaStatisticsOverview = {
  __typename?: 'MediaStatisticsOverview';
  /** Number of assigned learners */
  assignedLearners: Scalars['Int'];
  /** Number of completions */
  assignedLearnersDetails: Array<Maybe<MediaDetailsStatisticsGql>>;
  /** Course title */
  courseTitle: LangString;
  /** Course id */
  createdDate: Scalars['String'];
  /** Course id */
  id: Scalars['String'];
  /** Item Identifier */
  itemIdentifier: Scalars['String'];
  /** Last course call */
  lastCourseCall: Scalars['String'];
  /** Number of calls */
  numberOfCalls: Scalars['Int'];
  /** Number of completions */
  numberOfCompletions: Scalars['Int'];
  /** UUID */
  uuid: Scalars['UUID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** GraphQL mutation for creating/upserting tokens */
  authorizationToken: AuthorizationTokenMutation;
  /** GraphQL mutation for managing badges */
  badgeMutationGql?: Maybe<BadgesMutation>;
  /** GraphQL mutation for managing badges notifications */
  badgeNotificationsMutationGql?: Maybe<BadgeNotificationsMutation>;
  /** GraphQL mutation for managing Catalog favorites. */
  catalagoueFavouriteMutation?: Maybe<CatalogueFavouriteMutation>;
  /** GraphQL mutation for managing Categories/Topics */
  category?: Maybe<CategoryMutation>;
  /** GraphQL mutation for managing Certificates */
  certificateMutation?: Maybe<CertificatesMutation>;
  /** GraphQL mutation for managing Configurations */
  configsMutation?: Maybe<ConfigsMutation>;
  /** GraphQL mutation for managing CourseEvaluation */
  courseEvaluationMutation?: Maybe<CourseEvaluationMutation>;
  /** GraphQL mutation for managing favorite courses. */
  courseFavourite?: Maybe<CourseFavouriteMutation>;
  /** GraphQL mutation for managing Groups. */
  groupMutation?: Maybe<GroupMutation>;
  /** GraphQL mutation for managing HelpAdmin data */
  helpAdminMutation?: Maybe<HelpAdminMutation>;
  /** GraphQL mutation for managing icons */
  iconMutationGql?: Maybe<IconMutation>;
  /** GraphQL mutation for managing MailTemplates */
  mailTemplatesMutation?: Maybe<MailTemplatesMutation>;
  /** Generate fresh UUIDs */
  newUUID: Array<Scalars['UUID']>;
  /** GraphQL mutation for News */
  news?: Maybe<NewsMutationGql>;
  /** GraphQL mutation for managing nuggets */
  nuggetMutationGql?: Maybe<NuggetMutation>;
  /** GraphQL mutation for managing relatives */
  parentMutationGql?: Maybe<ParentMutationGql>;
  /** GraphQL mutation for managing Roles */
  roleMutation?: Maybe<RoleMutationGql>;
  /** GraphQL mutation for managing Scorm attempts */
  scorm12Attempt: Scorm12AttemptMutation;
  /** GraphQL mutation for managing Scorm Courses */
  scorm12Course: Scorm12CourseMutation;
  /** GraphQL mutation for managing enrolled scorm Courses */
  scorm12EnrolledCourse: EnrolledCourseMutation;
  /** GraphQL mutation for managing ScormItems */
  scorm12Item: Scorm12ItemMutation;
  /** GraphQL mutation for managing SelfRegistration */
  selfRegistrationMutation?: Maybe<SelfRegistrationMutationGql>;
  /** Query for setting systems Primary Color */
  setPrimaryColor: Scalars['Boolean'];
  /** GraphQL mutation for managing Units. */
  unitMutation?: Maybe<UnitMutation>;
  /** GraphQL mutation for managing Users */
  user: UserMutation;
  /** GraphQL mutation for managing Widgets */
  widget: WidgetMutation;
  /** GraphQL mutation for managing zulip chat */
  zulipChatMutation?: Maybe<ZulipChatMutation>;
};


export type MutationNewUuidArgs = {
  count: Scalars['Int'];
};


export type MutationSetPrimaryColorArgs = {
  primaryColor?: InputMaybe<Scalars['String']>;
};

/** A certificate */
export type MyCertificates = {
  __typename?: 'MyCertificates';
  /** Certificate present in course */
  courseHasCertificate: Scalars['Boolean'];
  /** Course uuid */
  courseUuid: Scalars['String'];
  /** Course name */
  name: LangString;
  /** User uuid */
  userUuid: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  audience: NewsAudienceEnum;
  author: User;
  creationDate: Scalars['Instant'];
  groups: Array<Maybe<Group>>;
  id: Scalars['ID'];
  isImportant: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  key: Scalars['Key'];
  message: LangString;
  publishedDate?: Maybe<Scalars['Instant']>;
  recipients: Scalars['Int'];
  sendToRelative: Scalars['Boolean'];
  sentGroupName: LangString;
  title: LangString;
  users: Array<Maybe<User>>;
  uuid: Scalars['UUID'];
};

export enum NewsAudienceEnum {
  Group = 'GROUP',
  User = 'USER'
}

export type NewsConnectionGql = {
  __typename?: 'NewsConnectionGQL';
  edges: Array<NewsEdgeGql>;
  nodes: Array<News>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NewsEdgeGql = {
  __typename?: 'NewsEdgeGQL';
  cursor: Scalars['String'];
  node: News;
};

export type NewsFiltersInput = {
  isPublished?: InputMaybe<Scalars['Boolean']>;
  publishedDate?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NewsMutationGql = {
  __typename?: 'NewsMutationGQL';
  deactivateNews: Scalars['Boolean'];
  deleteRange: Scalars['Boolean'];
  upsert: News;
};


export type NewsMutationGqlDeactivateNewsArgs = {
  input: Array<InputMaybe<IdOrKeyInput>>;
};


export type NewsMutationGqlDeleteRangeArgs = {
  input: Array<InputMaybe<IdOrKeyInput>>;
};


export type NewsMutationGqlUpsertArgs = {
  input: NewsUpsertInput;
};

export type NewsUpsertInput = {
  audience: NewsAudienceEnum;
  groups?: InputMaybe<Array<Scalars['UUID']>>;
  idOrKey: IdOrKeyInput;
  isImportant: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  message?: InputMaybe<UpsertLangStringInput>;
  sendToRelative: Scalars['Boolean'];
  title?: InputMaybe<UpsertLangStringInput>;
  users?: InputMaybe<Array<Scalars['UUID']>>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

/** GraphQL mutation to upsert nugget */
export type NuggetMutation = {
  __typename?: 'NuggetMutation';
  bulkDeleteScorm12Item: Array<Maybe<Scorm12Item>>;
  upsertChapter?: Maybe<Scorm12Item>;
  upsertReuseNugget?: Maybe<Array<Maybe<Scorm12Item>>>;
  upsertUploadNugget?: Maybe<Scorm12Item>;
};


/** GraphQL mutation to upsert nugget */
export type NuggetMutationBulkDeleteScorm12ItemArgs = {
  input: BulkDeleteScorm12ItemInput;
};


/** GraphQL mutation to upsert nugget */
export type NuggetMutationUpsertChapterArgs = {
  input: UpsertNuggetInputsInput;
};


/** GraphQL mutation to upsert nugget */
export type NuggetMutationUpsertReuseNuggetArgs = {
  input: BulkInsertReuseNuggetInput;
};


/** GraphQL mutation to upsert nugget */
export type NuggetMutationUpsertUploadNuggetArgs = {
  inputs: UpsertNuggetInputsInput;
};

export type Page = {
  __typename?: 'Page';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedResultOfCategoryRefactor = {
  __typename?: 'PaginatedResultOfCategoryRefactor';
  data?: Maybe<Array<Maybe<CategoryRefactor>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfGroupRefactored = {
  __typename?: 'PaginatedResultOfGroupRefactored';
  data?: Maybe<Array<Maybe<GroupRefactored>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfImportUsersRefactor = {
  __typename?: 'PaginatedResultOfImportUsersRefactor';
  data?: Maybe<Array<Maybe<ImportUsersRefactor>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfMediaDetailsStatisticsGql = {
  __typename?: 'PaginatedResultOfMediaDetailsStatisticsGQL';
  data?: Maybe<Array<Maybe<MediaDetailsStatisticsGql>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfMediaGqlRefactored = {
  __typename?: 'PaginatedResultOfMediaGQLRefactored';
  data?: Maybe<Array<Maybe<MediaGqlRefactored>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfScorm12Course = {
  __typename?: 'PaginatedResultOfSCORM12Course';
  data?: Maybe<Array<Maybe<Scorm12Course>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type PaginatedResultOfScorm12EnrolledCourseDto = {
  __typename?: 'PaginatedResultOfScorm12EnrolledCourseDto';
  data?: Maybe<Array<Maybe<Scorm12EnrolledCourseDto>>>;
  page?: Maybe<Page>;
  totalCount: Scalars['Int'];
};

export type ParentConnectionGql = {
  __typename?: 'ParentConnectionGQL';
  edges: Array<ParentEdgeGql>;
  nodes: Array<ParentGql>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ParentEdgeGql = {
  __typename?: 'ParentEdgeGQL';
  cursor: Scalars['String'];
  node: ParentGql;
};

export type ParentGql = {
  __typename?: 'ParentGQL';
  id: Scalars['ID'];
  key: Scalars['Key'];
  lastname?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  relatives?: Maybe<Array<Maybe<ParentGql>>>;
  uuid: Scalars['UUID'];
};

export type ParentMutationGql = {
  __typename?: 'ParentMutationGQL';
  deleteParent: ResponseWrapperOfBoolean;
};


export type ParentMutationGqlDeleteParentArgs = {
  input?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
};

export type ParentsFilterInput = {
  relativeName?: InputMaybe<Scalars['String']>;
  studentName?: InputMaybe<Scalars['String']>;
};

export type PersonAssignmnetTokenDto = {
  __typename?: 'PersonAssignmnetTokenDTO';
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** A position */
export type Position = {
  __typename?: 'Position';
  /** Defines cols of the position */
  cols: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** Defines rows of the position */
  rows: Scalars['Int'];
  uuid: Scalars['UUID'];
  /** Defines x of the position */
  x: Scalars['Int'];
  /** Defines y of the position */
  y: Scalars['Int'];
};

export type PositionInput = {
  cols: Scalars['Int'];
  rows: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

/** A course object used before the course is uploaded */
export type PreConfirmScorm12CourseGql = {
  __typename?: 'PreConfirmScorm12CourseGQL';
  /** All badges */
  allBadges: Array<Maybe<Badges>>;
  /** All categories */
  allCategories: Array<Maybe<Category>>;
  /** All certificates */
  allCertificates: Array<Maybe<Certificate>>;
  /** All groups */
  allGroups: Array<Maybe<Group>>;
  /** Assigned categories count */
  assignedCategoriesCount: Scalars['Int'];
  /** Assigned groups count */
  assignedGroupsCount: Scalars['Int'];
  /** Assigned learners */
  assignedLearners?: Maybe<Array<Maybe<MediaDetailsStatisticsGql>>>;
  /** Categories assigned to the course */
  categories: Array<Maybe<Category>>;
  /** Categories not assigned to the course */
  categoriesNotAssigned: Array<Maybe<Category>>;
  /** Count of chapters */
  chaptersCount: Scalars['Int'];
  /** Course evaluation */
  courseEvaluations: Array<Maybe<CourseEvaluationGql>>;
  courseMediaURL?: Maybe<Scalars['String']>;
  /** Returns full name of the creator */
  createdBy: Scalars['String'];
  /** Date when Course was created. */
  createdDate: Scalars['Instant'];
  /** Dashboard Mode */
  dashboardMode?: Maybe<Scalars['String']>;
  /** Defines description of the course */
  description: LangString;
  /** Enable Badge */
  enableBadge?: Maybe<CourseUploadAccessibility>;
  /** Enable Dashboard Mode */
  enableDashboardMode: Scalars['Boolean'];
  /** Course enrolled details */
  enrolledDetails?: Maybe<MediaDetailsStatisticsGql>;
  /** Date when Course is expired. */
  expirationDate?: Maybe<Scalars['Instant']>;
  /** Groups assigned to the course */
  groups: Array<Maybe<Group>>;
  /** Groups not assigned to the course */
  groupsNotAssigned: Array<Maybe<Group>>;
  /** Check if there is a draft version for the course */
  hasDraft?: Maybe<Scalars['Boolean']>;
  /** HistoryData */
  historyData: Array<Maybe<CourseVersionGql>>;
  id: Scalars['ID'];
  /** Image */
  image?: Maybe<Scalars['String']>;
  /** Image Type */
  imageType?: Maybe<Scalars['String']>;
  /** Defines if the course is active */
  isActive: Scalars['Boolean'];
  /** Defines if the course is booked */
  isBooked: Scalars['Boolean'];
  /** Defines if the course is deleted */
  isDeleted: Scalars['Boolean'];
  /** Defines if the course is favourite for the logged in user */
  isFavourite: Scalars['Boolean'];
  /** Check if the course is a learning path */
  isLearningPath: Scalars['Boolean'];
  /** Defines if the course is published */
  isPublished: Scalars['Boolean'];
  /** Course item identifier */
  itemIdentifier: Scalars['String'];
  key: Scalars['Key'];
  /** Language */
  language: Scalars['String'];
  /** Last publish date */
  lastPublishDate?: Maybe<Scalars['Instant']>;
  /** Count of chapters */
  learningPathChaptersCount: Scalars['Int'];
  /** Count of lessons */
  learningPathLessonsCount: Scalars['Int'];
  /** Calls the SCOs of the course */
  learningPathScos: Array<Maybe<Scorm12Item>>;
  /** Count of lessons */
  lessonsCount: Scalars['Int'];
  /** Media type */
  mediaType?: Maybe<Scalars['String']>;
  /** MIT Custom Tag */
  mit?: Maybe<Scalars['String']>;
  /** Defines name of the course */
  name: LangString;
  /** Calls the SCOs of the course */
  scos: Array<Maybe<Scorm12Item>>;
  /** Selected Badge */
  selectedBadge?: Maybe<Badges>;
  /** Selected certificate */
  selectedCertificate?: Maybe<Certificate>;
  /** Defines if the course is shown in catalog */
  showInCatalog: Scalars['Boolean'];
  /** Total learning time */
  totalLearningTime?: Maybe<Scalars['Duration']>;
  /** Count of items that have a launch uri (are trackable) */
  trackableItems: Scalars['Int'];
  /** Typical Learning Time (when set) */
  typicalLearningTime?: Maybe<Scalars['Duration']>;
  /** Date when Course was last updated. */
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
  /** Current Version */
  version?: Maybe<Scalars['String']>;
  /** Video Or Pdf Content */
  videoOrPdfContent?: Maybe<FileInfoResult>;
};

/** The rules that can make a role. Users get the privileges of each rule of each role */
export type Privilege = {
  __typename?: 'Privilege';
  /** The description of the rule */
  description: LangString;
  id: Scalars['ID'];
  key: Scalars['Key'];
  /** The (short) name of the rule */
  name: LangString;
  uuid: Scalars['UUID'];
};

export enum ProfileSortingItems {
  Date = 'DATE',
  Role = 'ROLE',
  Title = 'TITLE'
}

export type PromoteUserInput = {
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  roleId?: InputMaybe<Scalars['String']>;
  unitIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type PublishLearningPathInput = {
  courseUuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<UpsertLangStringInput>;
  title?: InputMaybe<UpsertLangStringInput>;
};

export enum PublishStatus {
  Enrollable = 'ENROLLABLE',
  Invisible = 'INVISIBLE'
}

export type Query = {
  __typename?: 'Query';
  /** GraphQL query type for retrieving Assistant data */
  assistant?: Maybe<QueryAssistant>;
  /** GraphQL query for Attempts, which includes methods to query and retrieve data from a database */
  attempts: QueryScorm12Attempts;
  /** GraqhQL query type for retrieving Badges data */
  badge?: Maybe<QueryBadgesGql>;
  /** GraqhQL query type for retrieving Badges data */
  badgeNotifications?: Maybe<QueryBadgeNotificationsGql>;
  /** GraphQL queries for retrieving and updating categories/topics in the application */
  categories: QueryCategoriesGql;
  /** GraphQL queries related to certificates in the application, including fetching all certificates and generating certificate previews */
  certificates?: Maybe<QueryCertificatesGql>;
  /** GraphQL query type for retrieving a single instance of ConfigGQL */
  configs?: Maybe<QueryConfigGql>;
  /** GraphQL query type for retrieving Groups */
  groups?: Maybe<QueryGroupsGql>;
  /** GraphQL query class for HelpAdmin entities, providing operations for retrieving and removing HelpAdmin data. */
  helpAdmin?: Maybe<QueryHelpAdminGql>;
  /** GraqhQL query type for retrieving icon data */
  icon?: Maybe<QueryIconGql>;
  /** GraphQL query class for retrieving and filtering MailTemplate data from the database, including getting all MailTemplate data and getting MailTemplate data by ID. */
  mailTemplates?: Maybe<QueryEmailTemplatesGql>;
  /** GraphQL queries for media, including retrieving paginated results and updating enrolled courses' active status. */
  media?: Maybe<QueryMediaGql>;
  /** GraphQL query for news */
  news?: Maybe<QueryNews>;
  /** GraqhQL query type for retrieving nugget data */
  nugget?: Maybe<QueryNuggetGql>;
  /** GraqhQL query type for retrieving relatives data */
  parent?: Maybe<QueryParentGql>;
  /** Query for getting systems Primary Color */
  primaryColor: Scalars['String'];
  /** GraqhQL query type for retrieving zulip chat  data */
  queryZulip?: Maybe<QueryZulip>;
  /**  GraphQL query class for querying and retrieving roles, implementing a connection and edge class for pagination and returning a single role or list of roles based on specified arguments. */
  roles?: Maybe<QueryRoles>;
  /** GraphQL query type for retrieving course accessibility status */
  scorm12CourseUploadAccessibility?: Maybe<QueryUploadAccessibilityGql>;
  /** GraphQl queries for retriving and exporting Scorm Courses, courses for the catalogue and media statistics */
  scorm12Courses: QueryScorm12Courses;
  /** GraphQL query for Scorm12EnrolledCourses, which includes methods to query and retrieve data from a database */
  scorm12EnrolledCourses: QueryScorm12EnrolledCourses;
  /** GraphQL query for Scorm12Items, which includes methods to query and retrieve data from a database */
  scorm12Items: QueryScorm12ItemsGql;
  /** GraphQL query type for retrieving SelfRegistration data */
  selfRegistrations?: Maybe<QuerySelfRegistrationGql>;
  /** GraphQL query type for retrieving Units */
  units?: Maybe<QueryUnitsGql>;
  /** GraphQL queries for retrieving and exporting User related data like: groups, certificates, statistics */
  users: QueryUserGql;
  /** Query for retriving the version */
  version: Scalars['String'];
  /** GraphQL query type for retrieving different kinds of Widgeds based on user roles */
  widgets: QueryWidgetsGql;
};

/** Queries related to Assistant */
export type QueryAssistant = {
  __typename?: 'QueryAssistant';
  getUrl: AssistantGql;
};

/** GraphQL queries related to badge notifications */
export type QueryBadgeNotificationsGql = {
  __typename?: 'QueryBadgeNotificationsGQL';
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  notificationBadgeForUser?: Maybe<BadgeNotificationsDto>;
  userBadges?: Maybe<Array<Maybe<UserBadgesDto>>>;
};

/** GraphQL queries related to badges */
export type QueryBadgesGql = {
  __typename?: 'QueryBadgesGQL';
  allCourseBadges: Array<BadgeDto>;
  allSystemBadges: Array<Maybe<BadgeDto>>;
  byIdCourseBadge: Badges;
  byIdSystemBadge: Badges;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


/** GraphQL queries related to badges */
export type QueryBadgesGqlAllCourseBadgesArgs = {
  language: LocalizationEnum;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** GraphQL queries related to badges */
export type QueryBadgesGqlAllSystemBadgesArgs = {
  language: LocalizationEnum;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** GraphQL queries related to badges */
export type QueryBadgesGqlByIdCourseBadgeArgs = {
  idOrKey: IdOrKeyInput;
};


/** GraphQL queries related to badges */
export type QueryBadgesGqlByIdSystemBadgeArgs = {
  idOrKey: IdOrKeyInput;
};

/** Queries related to Categories/Topics */
export type QueryCategoriesGql = {
  __typename?: 'QueryCategoriesGQL';
  all: Array<Maybe<Category>>;
  allCategoriesList: Array<Maybe<Category>>;
  allNonEmptyCourseListCategories: Array<Maybe<Category>>;
  allWithFilters: PaginatedResultOfCategoryRefactor;
  byId: Category;
  enrolledCourses: PaginatedResultOfScorm12EnrolledCourseDto;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  simpleCategories: Array<Maybe<CategoryDto>>;
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  status: CourseCompletionStatus;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlAllNonEmptyCourseListCategoriesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  status: CourseCompletionStatus;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlAllWithFiltersArgs = {
  filters?: InputMaybe<TopicsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  status: CourseCompletionStatus;
  take: Scalars['Int'];
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlEnrolledCoursesArgs = {
  categoryUuid?: InputMaybe<Scalars['String']>;
  isOnlyMandatory?: Scalars['Boolean'];
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status: CourseCompletionStatus;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Categories/Topics */
export type QueryCategoriesGqlSimpleCategoriesArgs = {
  isOnlyMandatory?: InputMaybe<Scalars['Boolean']>;
  pageIndex?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  status: CourseCompletionStatus;
  take?: InputMaybe<Scalars['Int']>;
};

/** GraphQL queries  related to certificates */
export type QueryCertificatesGql = {
  __typename?: 'QueryCertificatesGQL';
  all: Array<Maybe<Certificate>>;
  byId: Certificate;
  certificatePreview: CertPreviewGql;
  generateCertificate: CertGenerateGql;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  userLocal?: Maybe<Scalars['String']>;
};


/** GraphQL queries  related to certificates */
export type QueryCertificatesGqlAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** GraphQL queries  related to certificates */
export type QueryCertificatesGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};


/** GraphQL queries  related to certificates */
export type QueryCertificatesGqlCertificatePreviewArgs = {
  input: CertificatePreviewInput;
};


/** GraphQL queries  related to certificates */
export type QueryCertificatesGqlGenerateCertificateArgs = {
  input: CertificateDownloadInput;
};


/** GraphQL queries  related to certificates */
export type QueryCertificatesGqlUserLocalArgs = {
  idOrKey?: InputMaybe<IdOrKeyInput>;
};

/** Queries related to application Configs */
export type QueryConfigGql = {
  __typename?: 'QueryConfigGQL';
  configData: Config;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};

/** Queries related to Email Templates */
export type QueryEmailTemplatesGql = {
  __typename?: 'QueryEmailTemplatesGQL';
  all: Array<Maybe<Mailtemplate>>;
  byId: Mailtemplate;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


/** Queries related to Email Templates */
export type QueryEmailTemplatesGqlAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Email Templates */
export type QueryEmailTemplatesGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

/** Queries related to Groups */
export type QueryGroupsGql = {
  __typename?: 'QueryGroupsGQL';
  all: Array<Maybe<Group>>;
  allFiltered: PaginatedResultOfGroupRefactored;
  byId: Group;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


/** Queries related to Groups */
export type QueryGroupsGqlAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Groups */
export type QueryGroupsGqlAllFilteredArgs = {
  filters?: InputMaybe<GroupsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Groups */
export type QueryGroupsGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

/** Queries related to HelpAdmin */
export type QueryHelpAdminGql = {
  __typename?: 'QueryHelpAdminGQL';
  helpAdminData: HelpAdmin_Output;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  removeHelpAdminData: Scalars['Int'];
};

/** GraphQL queries related to icons */
export type QueryIconGql = {
  __typename?: 'QueryIconGQL';
  allIcons: Array<Maybe<Icon>>;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};

/** Queries related to Medias */
export type QueryMediaGql = {
  __typename?: 'QueryMediaGQL';
  all: PaginatedResultOfMediaGqlRefactored;
};


/** Queries related to Medias */
export type QueryMediaGqlAllArgs = {
  filters?: InputMaybe<MediaFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryNews = {
  __typename?: 'QueryNews';
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  newsById: News;
  newsByUserId: NewsConnectionGql;
  newsForLoggedUser: NewsConnectionGql;
  newsOfLoggedCreator: NewsConnectionGql;
};


export type QueryNewsNewsByIdArgs = {
  newsUuid: Scalars['UUID'];
};


export type QueryNewsNewsByUserIdArgs = {
  input?: InputMaybe<IdOrKeyInput>;
};


export type QueryNewsNewsOfLoggedCreatorArgs = {
  filters?: InputMaybe<NewsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** GraphQL queries related to nuggets */
export type QueryNuggetGql = {
  __typename?: 'QueryNuggetGQL';
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  reusableNuggets?: Maybe<Array<Maybe<ReusableNuggetDto>>>;
};


/** GraphQL queries related to nuggets */
export type QueryNuggetGqlReusableNuggetsArgs = {
  search?: InputMaybe<Scalars['String']>;
};

export type QueryParentGql = {
  __typename?: 'QueryParentGQL';
  all?: Maybe<ParentConnectionGql>;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


export type QueryParentGqlAllArgs = {
  filters?: InputMaybe<ParentsFilterInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Queries related to Roles */
export type QueryRoles = {
  __typename?: 'QueryRoles';
  all: Array<Maybe<Role>>;
  byId: Role;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  promotionRoles: Array<Maybe<Role>>;
};


/** Queries related to Roles */
export type QueryRolesAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Roles */
export type QueryRolesByIdArgs = {
  idOrKey: Scalars['ID'];
};

/** Query related to Scorm12Items */
export type QueryScorm12ItemsGql = {
  __typename?: 'QuerySCORM12ItemsGQL';
  all: ScoConnectionGql;
  byId: Scorm12Item;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


/** Query related to Scorm12Items */
export type QueryScorm12ItemsGqlAllArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ScoConnectionGqlSortInput>>;
  where?: InputMaybe<ScoConnectionGqlFilterInput>;
};


/** Query related to Scorm12Items */
export type QueryScorm12ItemsGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

/** Query ItemAttempts */
export type QueryScorm12Attempts = {
  __typename?: 'QueryScorm12Attempts';
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  lastVisitedLesson?: Maybe<AttemptGql>;
};


/** Query ItemAttempts */
export type QueryScorm12AttemptsLastVisitedLessonArgs = {
  courseIdOrKey: IdOrKeyInput;
};

/** Queries related to Scorm12Courses */
export type QueryScorm12Courses = {
  __typename?: 'QueryScorm12Courses';
  all: CourseConnectionGql;
  allLearningPaths: Array<Maybe<Scorm12Course>>;
  assignOptions: Group;
  assignmentOverview: PaginatedResultOfMediaDetailsStatisticsGql;
  byId: Scorm12Course;
  catalogItemsByIds: Array<Maybe<Scorm12Course>>;
  catalogue: PaginatedResultOfScorm12Course;
  dashboardMode?: Maybe<Scalars['String']>;
  exportMediaDetailsStatistics: ExportBase64Gql;
  exportMediaRecurringStatistics: ExportBase64Gql;
  exportMediaStatistics: ExportBase64Gql;
  hasMoreThanTreeLevels: Scalars['Boolean'];
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  mediaStatistics: Array<Maybe<MediaStatisticsOverview>>;
  uploadStatus: Upload_Status;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesAllArgs = {
  order?: InputMaybe<Array<CourseConnectionGqlSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseConnectionGqlFilterInput>;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesAssignOptionsArgs = {
  course?: InputMaybe<IdOrKeyInput>;
  group?: InputMaybe<IdOrKeyInput>;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesAssignmentOverviewArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesByIdArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesCatalogItemsByIdsArgs = {
  idOrKeys: Array<InputMaybe<IdOrKeyInput>>;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesCatalogueArgs = {
  categoryUuid?: InputMaybe<Scalars['String']>;
  filterByFavourites: Scalars['Boolean'];
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortingCondition: CatalogSortingItems;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesDashboardModeArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesExportMediaDetailsStatisticsArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesExportMediaRecurringStatisticsArgs = {
  input: ExportRecurringInput;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesHasMoreThanTreeLevelsArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12Courses */
export type QueryScorm12CoursesMediaStatisticsArgs = {
  filters?: InputMaybe<MediaStatisticsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Queries related to Scorm12EnrolledCourses */
export type QueryScorm12EnrolledCourses = {
  __typename?: 'QueryScorm12EnrolledCourses';
  addEntryCount: Scalars['Boolean'];
  all: Scorm12EnrolledCourseConnection;
  byId: Scorm12EnrolledCourse;
  calendarData: Scorm12EnrolledCourseConnection;
  calendarDataByUserId: Scorm12EnrolledCourseConnection;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  myCourses: Scorm12EnrolledCourseConnection;
};


/** Queries related to Scorm12EnrolledCourses */
export type QueryScorm12EnrolledCoursesAddEntryCountArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12EnrolledCourses */
export type QueryScorm12EnrolledCoursesByIdArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Scorm12EnrolledCourses */
export type QueryScorm12EnrolledCoursesCalendarDataByUserIdArgs = {
  input?: InputMaybe<IdOrKeyInput>;
};


/** Queries related to Scorm12EnrolledCourses */
export type QueryScorm12EnrolledCoursesMyCoursesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QuerySelfRegistrationGql = {
  __typename?: 'QuerySelfRegistrationGQL';
  acceptanceList: Array<Maybe<User>>;
  /** Queries for retriving SelfRegistration data */
  all: Array<Maybe<SelfRegistrationGql>>;
  byId: SelfRegistrationGql;
};


export type QuerySelfRegistrationGqlAllArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySelfRegistrationGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

/** Queries related to Units */
export type QueryUnitsGql = {
  __typename?: 'QueryUnitsGQL';
  all: Array<Maybe<Unit>>;
  allDropDown: Array<Maybe<Unit>>;
  byId: Unit;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
};


/** Queries related to Units */
export type QueryUnitsGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

/** Queries related to media upload accessibility */
export type QueryUploadAccessibilityGql = {
  __typename?: 'QueryUploadAccessibilityGQL';
  badge: CourseUploadAccessibility;
  calendarWidget: CourseUploadAccessibility;
  chatbot: CourseUploadAccessibility;
  courseMailingStatus: CourseUploadAccessibility;
  courseRecommendationStatus: CourseUploadAccessibility;
  courseUploadAccessibilityStatus: CourseUploadAccessibility;
  evaluation: CourseUploadAccessibility;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  parent: CourseUploadAccessibility;
  zulipChat: CourseUploadAccessibility;
};

/** Queries related to Users */
export type QueryUserGql = {
  __typename?: 'QueryUserGQL';
  all: UserConnectionGql;
  byId: User;
  currentUser: User;
  downloadCSVTemplate: ExportBase64Gql;
  downloadExcelTemplate: ExportBase64Gql;
  exportUserDetailsStatistics: ExportBase64Gql;
  exportUserRecurringStatistics: ExportBase64Gql;
  exportUserStatistics: ExportBase64Gql;
  getCertificates: User;
  getGroups: User;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  importLogs: Array<Maybe<ImportUsers>>;
  importLogsFiltered: PaginatedResultOfImportUsersRefactor;
  learningManagers: Array<Maybe<User>>;
  loggedUserChildUuid: User;
  systemLanguage: Array<Maybe<Scalars['String']>>;
  userStatistics: Array<Maybe<UserStatisticsOverviewGql>>;
  usersOfByUserRole: UserConnectionGql;
};


/** Queries related to Users */
export type QueryUserGqlAllArgs = {
  filters?: InputMaybe<UsersFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Users */
export type QueryUserGqlByIdArgs = {
  idOrKey?: InputMaybe<IdOrKeyInput>;
};


/** Queries related to Users */
export type QueryUserGqlExportUserDetailsStatisticsArgs = {
  idOrKey: IdOrKeyInput;
};


/** Queries related to Users */
export type QueryUserGqlExportUserRecurringStatisticsArgs = {
  input: ExportRecurringInput;
};


/** Queries related to Users */
export type QueryUserGqlGetCertificatesArgs = {
  profileSorting?: InputMaybe<ProfileSortingItems>;
};


/** Queries related to Users */
export type QueryUserGqlGetGroupsArgs = {
  profileSorting?: InputMaybe<ProfileSortingItems>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Users */
export type QueryUserGqlImportLogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Users */
export type QueryUserGqlImportLogsFilteredArgs = {
  filters?: InputMaybe<ImportLogsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Users */
export type QueryUserGqlLearningManagersArgs = {
  unitUuid?: InputMaybe<Scalars['String']>;
};


/** Queries related to Users */
export type QueryUserGqlUserStatisticsArgs = {
  filters?: InputMaybe<UsersStatisticsFiltersInput>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queries related to Users */
export type QueryUserGqlUsersOfByUserRoleArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Queris related to Widgets */
export type QueryWidgetsGql = {
  __typename?: 'QueryWidgetsGQL';
  all: WidgetsConnectionGql;
  averageLerarningTime: AverageLearningTimeWidget;
  courseCompletionsInPerdiod: CourseCompletionsGql;
  coursesWidgetData: Array<Maybe<CourseHistoryAndLastVisitedWidget>>;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID'];
  lastCompletions: Array<Maybe<Scorm12EnrolledCourse>>;
  latestFromCatalogue: Array<Maybe<LatestCatalogueWidget>>;
  myBadges: Array<Maybe<BadgeCollection>>;
  myCerfitifaces: Array<Maybe<MyCertificates>>;
  newestMembers: Array<Maybe<User>>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlAverageLerarningTimeArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


/** Queris related to Widgets */
export type QueryWidgetsGqlCourseCompletionsInPerdiodArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


/** Queris related to Widgets */
export type QueryWidgetsGqlCoursesWidgetDataArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  status: CourseCompletionStatus;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlLastCompletionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlLatestFromCatalogueArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlMyBadgesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlMyCerfitifacesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Queris related to Widgets */
export type QueryWidgetsGqlNewestMembersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Queries zulip chat */
export type QueryZulip = {
  __typename?: 'QueryZulip';
  hasUnreadMessages: Scalars['Boolean'];
  markMessagesAsRead: Scalars['Boolean'];
  messages: ZulipStreamGql;
  topics: Array<Maybe<ZulipTopicGql>>;
};


/** Queries zulip chat */
export type QueryZulipHasUnreadMessagesArgs = {
  courseUuid: Scalars['String'];
};


/** Queries zulip chat */
export type QueryZulipMarkMessagesAsReadArgs = {
  courseUuid: Scalars['String'];
  topic: Scalars['String'];
};


/** Queries zulip chat */
export type QueryZulipMessagesArgs = {
  courseUuid: Scalars['String'];
  topic: Scalars['String'];
};


/** Queries zulip chat */
export type QueryZulipTopicsArgs = {
  courseUuid: Scalars['String'];
};

export type ResponseWrapperOfBoolean = {
  __typename?: 'ResponseWrapperOfBoolean';
  data: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  statusCode: HttpStatusCode;
};

export type ReusableNuggetDto = {
  __typename?: 'ReusableNuggetDto';
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  learningPathDetails?: Maybe<Array<Maybe<LearningPathDetail>>>;
  title?: Maybe<LangString>;
  topic?: Maybe<LangString>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

/**
 * A Role in the system.
 * Example:
 * - Admin
 * - Education-Manager
 * - Learner
 *
 */
export type Role = {
  __typename?: 'Role';
  description: LangString;
  /** One or more privileges that make this role */
  grantedPrivileges: Array<Maybe<Privilege>>;
  id: Scalars['ID'];
  key: Scalars['Key'];
  name?: Maybe<LangString>;
  uuid: Scalars['UUID'];
};

/** GraphQL mutation for updating and upserting Roles */
export type RoleMutationGql = {
  __typename?: 'RoleMutationGQL';
  upsert: Role;
};


/** GraphQL mutation for updating and upserting Roles */
export type RoleMutationGqlUpsertArgs = {
  input: UpsertRoleInput;
};

export type ScoConnectionGql = {
  __typename?: 'SCOConnectionGQL';
  edges: Array<ScoEdgeGql>;
  nodes: Array<Scorm12Item>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ScoConnectionGqlFilterInput = {
  and?: InputMaybe<Array<ScoConnectionGqlFilterInput>>;
  or?: InputMaybe<Array<ScoConnectionGqlFilterInput>>;
  totalCount?: InputMaybe<IntOperationFilterInput>;
};

export type ScoConnectionGqlSortInput = {
  totalCount?: InputMaybe<SortEnumType>;
};

export type ScoEdgeGql = {
  __typename?: 'SCOEdgeGQL';
  cursor: Scalars['String'];
  node: Scorm12Item;
};

/** A course */
export type Scorm12Course = {
  __typename?: 'SCORM12Course';
  /** All badges */
  allBadges: Array<Maybe<Badges>>;
  /** All categories */
  allCategories: Array<Maybe<Category>>;
  /** All certificates */
  allCertificates: Array<Maybe<Certificate>>;
  /** All groups */
  allGroups: Array<Maybe<Group>>;
  /** Assigned categories count */
  assignedCategoriesCount: Scalars['Int'];
  /** Assigned groups count */
  assignedGroupsCount: Scalars['Int'];
  /** Assigned learners */
  assignedLearners?: Maybe<Array<Maybe<MediaDetailsStatisticsGql>>>;
  /** Categories assigned to the course */
  categories: Array<Maybe<Category>>;
  /** Categories not assigned to the course */
  categoriesNotAssigned: Array<Maybe<Category>>;
  /** Count of chapters */
  chaptersCount: Scalars['Int'];
  /** Course evaluation */
  courseEvaluations: Array<Maybe<CourseEvaluationGql>>;
  /** Course media URL */
  courseMediaURL?: Maybe<Scalars['String']>;
  /** Returns full name of the creator */
  createdBy: Scalars['String'];
  /** Date when Course was created. */
  createdDate: Scalars['Instant'];
  /** Dashboard Mode */
  dashboardMode?: Maybe<Scalars['String']>;
  /** Defines description of the course */
  description: LangString;
  /** Enable Badge */
  enableBadge?: Maybe<CourseUploadAccessibility>;
  /** Enable Dashboard Mode */
  enableDashboardMode: Scalars['Boolean'];
  /** Course enrolled details */
  enrolledDetails?: Maybe<MediaDetailsStatisticsGql>;
  /** Date when Course is expired. */
  expirationDate?: Maybe<Scalars['Instant']>;
  /** Groups assigned to the course */
  groups: Array<Maybe<Group>>;
  /** Groups not assigned to the course */
  groupsNotAssigned: Array<Maybe<Group>>;
  /** Check if there is a draft version for the course */
  hasDraft?: Maybe<Scalars['Boolean']>;
  /** HistoryData */
  historyData: Array<Maybe<CourseVersionGql>>;
  id: Scalars['ID'];
  /** Image */
  image?: Maybe<Scalars['String']>;
  /** Image Type */
  imageType?: Maybe<Scalars['String']>;
  /** Defines if the course is active */
  isActive: Scalars['Boolean'];
  /** Defines if the course is booked */
  isBooked: Scalars['Boolean'];
  /** Defines if the course is deleted */
  isDeleted: Scalars['Boolean'];
  /** Defines if the course is favourite for the logged in user */
  isFavourite: Scalars['Boolean'];
  /** Check if the course is a learning path */
  isLearningPath: Scalars['Boolean'];
  /** Defines if the course is published */
  isPublished: Scalars['Boolean'];
  /** Course item identifier */
  itemIdentifier: Scalars['String'];
  key: Scalars['Key'];
  /** Language */
  language: Scalars['String'];
  /** Last publish date */
  lastPublishDate?: Maybe<Scalars['Instant']>;
  /** Count of chapters */
  learningPathChaptersCount: Scalars['Int'];
  /** Count of lessons */
  learningPathLessonsCount: Scalars['Int'];
  /** Calls the SCOs of the course */
  learningPathScos: Array<Maybe<Scorm12Item>>;
  /** Count of lessons */
  lessonsCount: Scalars['Int'];
  /** Media type */
  mediaType?: Maybe<Scalars['String']>;
  /** MIT Custom Tag */
  mit?: Maybe<Scalars['String']>;
  /** Defines name of the course */
  name: LangString;
  /** Calls the SCOs of the course */
  scos: Array<Maybe<Scorm12Item>>;
  /** Selected Badge */
  selectedBadge?: Maybe<Badges>;
  /** Selected certificate */
  selectedCertificate?: Maybe<Certificate>;
  /** Defines if the course is shown in catalog */
  showInCatalog: Scalars['Boolean'];
  /** Total learning time */
  totalLearningTime?: Maybe<Scalars['Duration']>;
  /** Count of items that have a launch uri (are trackable) */
  trackableItems: Scalars['Int'];
  /** Typical Learning Time (when set) */
  typicalLearningTime?: Maybe<Scalars['Duration']>;
  /** Date when Course was last updated. */
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
  /** Current Version */
  version?: Maybe<Scalars['String']>;
  /** Video Or Pdf Content */
  videoOrPdfContent?: Maybe<FileInfoResult>;
};

/** GraphQL mutation for updating course completion status and commiting an attempt to the database */
export type Scorm12AttemptMutation = {
  __typename?: 'Scorm12AttemptMutation';
  commit: Scorm12EnrolledItem;
  launchSco: LaunchScoResult;
};


/** GraphQL mutation for updating course completion status and commiting an attempt to the database */
export type Scorm12AttemptMutationCommitArgs = {
  input: CommitScorm12AttemptInput;
};


/** GraphQL mutation for updating course completion status and commiting an attempt to the database */
export type Scorm12AttemptMutationLaunchScoArgs = {
  enrolledCourseLink: IdOrKeyInput;
  itemLink: IdOrKeyInput;
};

export type Scorm12CourseDto = {
  __typename?: 'Scorm12CourseDto';
  courseEvaluations?: Maybe<Array<Maybe<CourseEvaluationDto>>>;
  courseMediaURL?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserDto>;
  createdById?: Maybe<Scalars['Int']>;
  createdDate: Scalars['Instant'];
  deletedDate?: Maybe<Scalars['Instant']>;
  description?: Maybe<LangString>;
  expirationDate?: Maybe<Scalars['Instant']>;
  image?: Maybe<Scalars['String']>;
  imageType?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  name?: Maybe<LangString>;
  publishStatus: PublishStatus;
  scos?: Maybe<Array<Maybe<ScosDto>>>;
  selectedCertificate?: Maybe<CertificateDto>;
  showInCatalog: Scalars['Boolean'];
  typicalLearningTime?: Maybe<Scalars['Duration']>;
  updatedBy?: Maybe<UserDto>;
  updatedById?: Maybe<Scalars['Int']>;
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
};

/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutation = {
  __typename?: 'Scorm12CourseMutation';
  activateCatalog: Scorm12Course;
  activateMedia: Scorm12Course;
  assignMediaToGroup: Array<Maybe<Scorm12Course>>;
  batchUpsert: Array<Maybe<Scorm12Course>>;
  bookCourse: Scorm12EnrolledCourse;
  bulkActivateCatalog: Array<Maybe<Scorm12Course>>;
  bulkActivateMedia: Array<Maybe<Scorm12Course>>;
  bulkDeleteMedia: Array<Maybe<Scorm12Course>>;
  bulkUpdateMediaCategories: Array<Maybe<Scorm12Course>>;
  bulkUpdateMediaGroups: Array<Maybe<Scorm12Course>>;
  bulkUpsert: Array<Maybe<Scorm12Course>>;
  createVersion: Scorm12Course;
  deactivateMedia: Array<Maybe<Scorm12Course>>;
  deleteCourseUploadStatus: Upload_Status;
  deleteMedia: Scorm12Course;
  deleteVersion: Scorm12Course;
  enrollStudent: Scorm12EnrolledCourse;
  generateUniqueKey?: Maybe<Scalars['String']>;
  generateUniqueUuid: Scalars['UUID'];
  publishLearningPath: Scorm12Course;
  remove: Scalars['Boolean'];
  unBook: Scalars['Boolean'];
  updateCourseMedia: Scalars['Int'];
  updateMedia: Scalars['Boolean'];
  updateMediaCategories: Scorm12Course;
  updateMediaGroups: Scorm12Course;
  uploadCourseMetadata?: Maybe<Array<Maybe<PreConfirmScorm12CourseGql>>>;
  uploadCourses?: Maybe<Array<Maybe<Scorm12Course>>>;
  uploadedFileInfo: FileInfoReturn;
  upsert: Scorm12Course;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationActivateCatalogArgs = {
  input: ActivateCatalogInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationActivateMediaArgs = {
  input: ActivateMediaInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationAssignMediaToGroupArgs = {
  input: AssignCourseGroupInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBatchUpsertArgs = {
  inputs: Array<InputMaybe<UpsertCourseInput>>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBookCourseArgs = {
  input: BookCourseInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkActivateCatalogArgs = {
  input: BulkActivateCatalogInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkActivateMediaArgs = {
  input: BulkActivateMediaInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkDeleteMediaArgs = {
  input: BulkDeleteMediaInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkUpdateMediaCategoriesArgs = {
  input: BulkUpdateMediaCategoriesInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkUpdateMediaGroupsArgs = {
  input: BulkUpdateMediaGroupsInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationBulkUpsertArgs = {
  inputs: Array<InputMaybe<UpsertCourseInput>>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationCreateVersionArgs = {
  courseUuid?: InputMaybe<Scalars['String']>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationDeactivateMediaArgs = {
  input: DeactivateMediaInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationDeleteVersionArgs = {
  versionUuid?: InputMaybe<Scalars['String']>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationEnrollStudentArgs = {
  input: EnrollStudentInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationGenerateUniqueKeyArgs = {
  length?: Scalars['Int'];
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationPublishLearningPathArgs = {
  input?: InputMaybe<PublishLearningPathInput>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUnBookArgs = {
  input: BookCourseInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUpdateCourseMediaArgs = {
  input: UploadCourseInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUpdateMediaArgs = {
  idOrKey: IdOrKeyInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUpdateMediaCategoriesArgs = {
  input: UpdateMediaCategoriesInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUpdateMediaGroupsArgs = {
  input: UpdateMediaGroupsInput;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUploadCourseMetadataArgs = {
  inputs: Array<InputMaybe<UploadCourseInput>>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUploadCoursesArgs = {
  inputs: Array<InputMaybe<UploadCourseInput>>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUploadedFileInfoArgs = {
  input?: InputMaybe<UploadFileInput>;
};


/** GraphQL mutation for uploading and updating Scrom course related data */
export type Scorm12CourseMutationUpsertArgs = {
  input: UpsertCourseInput;
};

/** Details of one Media assigned to a User  */
export type Scorm12EnrolledCourse = {
  __typename?: 'Scorm12EnrolledCourse';
  calendarData?: Maybe<CalendarData>;
  /** Completion date */
  completionDate: Scalars['String'];
  completionStatus: CourseCompletionStatus;
  course: Scorm12Course;
  daysLeft?: Maybe<Scalars['Int']>;
  enrolledItems: Array<Maybe<Scorm12EnrolledItem>>;
  id: Scalars['ID'];
  isFavourite: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  key: Scalars['Key'];
  skipCelebrate: Scalars['Boolean'];
  user: User;
  uuid: Scalars['UUID'];
};


/** Details of one Media assigned to a User  */
export type Scorm12EnrolledCourseEnrolledItemsArgs = {
  filter?: InputMaybe<EnrolledItemFilterInput>;
};

export type Scorm12EnrolledCourseConnection = {
  __typename?: 'Scorm12EnrolledCourseConnection';
  edges: Array<Scorm12EnrolledCourseEdge>;
  nodes: Array<Scorm12EnrolledCourse>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Scorm12EnrolledCourseDto = {
  __typename?: 'Scorm12EnrolledCourseDto';
  completionDate?: Maybe<Scalars['String']>;
  completionStatus: CourseCompletionStatus;
  course?: Maybe<Scorm12CourseDto>;
  daysLeft?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFavourite: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  user?: Maybe<UserDto>;
  uuid: Scalars['UUID'];
};

export type Scorm12EnrolledCourseEdge = {
  __typename?: 'Scorm12EnrolledCourseEdge';
  cursor: Scalars['String'];
  node: Scorm12EnrolledCourse;
};

/** Scorm12EnrolledItemGQL */
export type Scorm12EnrolledItem = {
  __typename?: 'Scorm12EnrolledItem';
  course: Scorm12Course;
  /** Whether the user gets credit or not. */
  credit?: Maybe<Scalars['String']>;
  enrolledCourse: Scorm12EnrolledCourse;
  /** Set by the LMS to indicate whether content is launched the first time or resumed */
  entry: Scorm12_Entry;
  id: Scalars['String'];
  item: Scorm12Item;
  /** LessonLocation is used to resume the content at the same place */
  lessonLocation?: Maybe<Scalars['String']>;
  /** Set by the content to determine completeness or success */
  lessonStatus: Scorm12_LessonStatus;
  /** The max score set by the content. Can be null when no score was set yet. */
  scoreMax?: Maybe<Scalars['Float']>;
  /** The min score set by the content. Can be null when no score was set yet. */
  scoreMin?: Maybe<Scalars['Float']>;
  /** The raw score set by the content. Can be null when no score was set yet. */
  scoreRaw?: Maybe<Scalars['Float']>;
  /** Suspend data is used by the content to store arbitrary information and retrieve it later */
  suspendData?: Maybe<Scalars['String']>;
  /** How much time did the user spent. */
  timeSpent: Scalars['Duration'];
};

/** SCO (Shareable Content Object) Items are entities that can be launched and tracked by the LMS */
export type Scorm12Item = {
  __typename?: 'Scorm12Item';
  /** The course that this SCO belongs to. An SCO only belongs to exactly one Course. */
  course?: Maybe<Scorm12Course>;
  /** Defines when the SCO is created. */
  createdDate: Scalars['Instant'];
  /** Defines a neccessary data that comes from imsmanifest.xml */
  dataFromLMS: Scalars['String'];
  description: LangString;
  /** File Upload */
  fileUpload?: Maybe<Scalars['String']>;
  /** Lesson Icon */
  icon?: Maybe<Scalars['String']>;
  /** Icon Upload */
  iconUpload?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Defines if item is mandatory */
  isMandatory?: Maybe<Scalars['Boolean']>;
  /** Is Reusable Nugget */
  isReusable?: Maybe<Scalars['Boolean']>;
  isTrackable: Scalars['Boolean'];
  key: Scalars['Key'];
  /** URI used to launch the SCO (from the imsmanifest.xml) */
  launchUri: Scalars['String'];
  manifestId: Scalars['String'];
  masteryScore?: Maybe<Scalars['Float']>;
  maxTimeAllowed?: Maybe<Scalars['Duration']>;
  /** Defines the metadata of the SCO data */
  metadata?: Maybe<Scalars['String']>;
  /** Defines the order of the SCO data */
  order: Scalars['Int'];
  parent?: Maybe<Scorm12Item>;
  /** Mit custom tag - subtitle */
  subtitle?: Maybe<Scalars['String']>;
  timeLimitAction: Scorm12_TimeLimitAction;
  /** Name of the SCO */
  title: LangString;
  /** Mit custom tag - type */
  type?: Maybe<Scalars['String']>;
  /** Typical Learning Time (when set) */
  typicalLearningTime?: Maybe<Scalars['Duration']>;
  /** Defines when the SCO is updated. */
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
};

/** GraphQl mutation for updating, upserting and removing the ScormItems */
export type Scorm12ItemMutation = {
  __typename?: 'Scorm12ItemMutation';
  remove: Scalars['Boolean'];
  upsert: Scorm12Item;
};


/** GraphQl mutation for updating, upserting and removing the ScormItems */
export type Scorm12ItemMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQl mutation for updating, upserting and removing the ScormItems */
export type Scorm12ItemMutationUpsertArgs = {
  input?: InputMaybe<UpsertScoInput>;
};

export enum Scorm12_Entry {
  Abinitio = 'ABINITIO',
  Resume = 'RESUME'
}

export enum Scorm12_LessonStatus {
  Browsed = 'BROWSED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  Notattempted = 'NOTATTEMPTED',
  Passed = 'PASSED'
}

export enum Scorm12_TimeLimitAction {
  ContinueMessage = 'CONTINUE_MESSAGE',
  ContinueNoMessage = 'CONTINUE_NO_MESSAGE',
  ExitMessage = 'EXIT_MESSAGE',
  ExitNoMessage = 'EXIT_NO_MESSAGE'
}

export type ScosDto = {
  __typename?: 'ScosDto';
  createdDate: Scalars['Instant'];
  dataFromLMS?: Maybe<Scalars['String']>;
  description?: Maybe<LangString>;
  isTrackable: Scalars['Boolean'];
  launchUri?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  masteryScore?: Maybe<Scalars['Float']>;
  maxTimeAlloed?: Maybe<Scalars['Duration']>;
  metadata?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  timeLimitAction: Scorm12_TimeLimitAction;
  title?: Maybe<LangString>;
  type?: Maybe<Scalars['String']>;
  typicalLearningTime?: Maybe<Scalars['Duration']>;
  updatedDate: Scalars['Instant'];
  uuid: Scalars['UUID'];
};

/** SelfRegistration */
export type SelfRegistrationGql = {
  __typename?: 'SelfRegistrationGQL';
  /** Expiration date of self registration link */
  expirationDate: Scalars['String'];
  /** Number of groups assigned to self generation link */
  groupCount: Scalars['Int'];
  /** Groups assigned to self generation link */
  groups: Array<Maybe<Group>>;
  /** Link for self registration */
  link: Scalars['String'];
  /** Status of self registration link */
  status: Scalars['String'];
  /** Title of self registration */
  title: Scalars['String'];
  /** Uuid */
  uuid: Scalars['UUID'];
  /** Number of whitelist domains assigned to self generation link */
  whiteListCount: Scalars['Int'];
  /** Whitelist domains assigned to self generation link */
  whiteLists: Array<Maybe<Scalars['String']>>;
};

export type SelfRegistrationInput = {
  groups?: InputMaybe<UpsertLinksInput>;
  idOrKey: IdOrKeyInput;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Key']>;
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userStatus?: InputMaybe<UserStatus>;
  uuid?: InputMaybe<Scalars['UUID']>;
  whitelists?: InputMaybe<Scalars['String']>;
};

/** GraphQl mutation for updating/upserting, acceping or denying, renew or delete Selfregistration data */
export type SelfRegistrationMutationGql = {
  __typename?: 'SelfRegistrationMutationGQL';
  acceptOrDenyUser: User;
  bulkAcceptOrDenyUser: Array<Maybe<User>>;
  renewOrDeleteLink: SelfRegistrationGql;
  upsert: SelfRegistrationGql;
};


/** GraphQl mutation for updating/upserting, acceping or denying, renew or delete Selfregistration data */
export type SelfRegistrationMutationGqlAcceptOrDenyUserArgs = {
  input: SelfRegistrationInput;
};


/** GraphQl mutation for updating/upserting, acceping or denying, renew or delete Selfregistration data */
export type SelfRegistrationMutationGqlBulkAcceptOrDenyUserArgs = {
  input: BulkAcceptanceListInput;
};


/** GraphQl mutation for updating/upserting, acceping or denying, renew or delete Selfregistration data */
export type SelfRegistrationMutationGqlRenewOrDeleteLinkArgs = {
  input: SelfRegistrationInput;
};


/** GraphQl mutation for updating/upserting, acceping or denying, renew or delete Selfregistration data */
export type SelfRegistrationMutationGqlUpsertArgs = {
  input: SelfRegistrationInput;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SystemBadgeTypesEnum {
  ActiveShopper = 'ACTIVE_SHOPPER',
  BadgeMaster = 'BADGE_MASTER',
  Champion = 'CHAMPION',
  Collector = 'COLLECTOR',
  FirstOrder = 'FIRST_ORDER',
  Hoarder = 'HOARDER',
  LoyalCustomer = 'LOYAL_CUSTOMER',
  Native = 'NATIVE',
  Newcomer = 'NEWCOMER',
  Pro = 'PRO',
  Resident = 'RESIDENT',
  Starter = 'STARTER',
  Visitor = 'VISITOR',
  X = 'X'
}

export type TopicsFiltersInput = {
  assignedCategoriesIds?: InputMaybe<Array<Scalars['UUID']>>;
  maxMediaCount?: InputMaybe<Scalars['Int']>;
  minMediaCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** A unit */
export type Unit = {
  __typename?: 'Unit';
  /** Unit id to display */
  displayedId: Scalars['String'];
  /** Defines number of groups per unit */
  groupCount: Scalars['Int'];
  /** Unit groups */
  groups: Array<Maybe<GroupRefactored>>;
  id: Scalars['ID'];
  /** Defines if unit is active */
  isActive: Scalars['Boolean'];
  /** Is general unit */
  isGeneral: Scalars['Boolean'];
  /** Is current user the unit manager */
  isUnitManager: Scalars['Boolean'];
  key: Scalars['Key'];
  /** Media assigned to the group */
  media: Array<Maybe<Scorm12Course>>;
  /** Defines number of medias per unit */
  mediaCount: Scalars['Int'];
  /** Defines name of the unit */
  name: Scalars['String'];
  /** Defines number of users per unit */
  userCount: Scalars['Int'];
  /** Unit users */
  users: Array<Maybe<User>>;
  uuid: Scalars['UUID'];
};

/** GraphQl mutation for updating and adding Units */
export type UnitMutation = {
  __typename?: 'UnitMutation';
  delete: Array<Maybe<Unit>>;
  updateUnitStatus: Array<Maybe<Unit>>;
  upsert: Unit;
};


/** GraphQl mutation for updating and adding Units */
export type UnitMutationDeleteArgs = {
  unitUuids: Array<InputMaybe<Scalars['String']>>;
};


/** GraphQl mutation for updating and adding Units */
export type UnitMutationUpdateUnitStatusArgs = {
  input: UpdateUnitStatusInput;
};


/** GraphQl mutation for updating and adding Units */
export type UnitMutationUpsertArgs = {
  input?: InputMaybe<AddUnitInput>;
};

export type UpdateCategoryMediasOrderInput = {
  categoryUuid?: InputMaybe<Scalars['String']>;
  courseUuid?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type UpdateCourseEvaluationInput = {
  idOrKey: IdOrKeyInput;
  isAnonym: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpdateCourseFavouriteInput = {
  favourite: Scalars['Boolean'];
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpdateGroupStatusInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  status: GroupStatus;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpdateMediaCategoriesInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  courseUuid?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaGroupsInput = {
  activateMedia?: InputMaybe<Scalars['Boolean']>;
  completionNotification?: InputMaybe<Scalars['Boolean']>;
  dateOfReminder?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Instant']>;
  groups?: InputMaybe<UpsertLinksInput>;
  idOrKey: IdOrKeyInput;
  intervall?: InputMaybe<IntervallTypeEnum>;
  intervallEndDate?: InputMaybe<Scalars['Instant']>;
  key?: InputMaybe<Scalars['Key']>;
  newCourseNotification?: InputMaybe<Scalars['Boolean']>;
  roleLinks?: InputMaybe<Array<InputMaybe<UpsertRoleAssignmentInput>>>;
  startDate?: InputMaybe<Scalars['Instant']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpdateUnitStatusInput = {
  isActive: Scalars['Boolean'];
  unitUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateUserGroupsInput = {
  groups?: InputMaybe<UpsertLinksInput>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpdateUserStatusInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  status: UserStatus;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UploadCourseInput = {
  duration?: InputMaybe<Scalars['Float']>;
  file?: InputMaybe<Scalars['Upload']>;
  forceUpdate?: InputMaybe<Scalars['Boolean']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UploadFileInput = {
  duration?: InputMaybe<Scalars['Float']>;
  file?: InputMaybe<Scalars['Upload']>;
};

/** Upload status */
export type Upload_Status = {
  __typename?: 'Upload_Status';
  /** Show upload status */
  isDeleted: Scalars['Boolean'];
  /** Actual status of process */
  status: CourseUploadStatus;
  /** Number of courses to be uploaded in a process */
  totalToUpload: Scalars['Int'];
  /** How many course are uploaded from latest process */
  totalUploaded: Scalars['Int'];
};

export type UpsertBadgeInput = {
  badgeType: BadgeTypeEnum;
  descriptionDe?: InputMaybe<Scalars['String']>;
  descriptionEn?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['Upload']>;
  idOrKey: IdOrKeyInput;
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEdit: Scalars['Boolean'];
  isSystemBadge: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  seriesDe?: InputMaybe<Scalars['String']>;
  seriesEn?: InputMaybe<Scalars['String']>;
  systemBadgeTypes?: InputMaybe<SystemBadgeTypesEnum>;
  titleDe?: InputMaybe<Scalars['String']>;
  titleEn?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['UUID']>;
  uuidPrimary?: InputMaybe<Scalars['String']>;
};

export type UpsertBadgeNotificationsInput = {
  badgeId?: InputMaybe<Scalars['Int']>;
  badgeType: BadgeTypeEnum;
  courseName?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  descriptionEn?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  imageUrl?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['Key']>;
  level?: InputMaybe<Scalars['Int']>;
  notificationShown?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertCategoryInput = {
  deleteFile?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<UpsertLangStringInput>;
  file?: InputMaybe<Scalars['Upload']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  name?: InputMaybe<UpsertLangStringInput>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertCertificateInput = {
  deleteFile?: InputMaybe<Scalars['Boolean']>;
  file?: InputMaybe<Scalars['Upload']>;
  headingDe?: InputMaybe<Scalars['String']>;
  headingEn?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  textDe?: InputMaybe<Scalars['String']>;
  textEn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  titleEn?: InputMaybe<Scalars['String']>;
  type: CertificateTypeEnum;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertCollectionOfStringInput = {
  add?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  set?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpsertConfigInput = {
  deleteFile?: InputMaybe<Scalars['Boolean']>;
  file?: InputMaybe<Scalars['Upload']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertCourseInput = {
  badge?: InputMaybe<IdOrKeyInput>;
  categories?: InputMaybe<UpsertLinksInput>;
  certificate?: InputMaybe<IdOrKeyInput>;
  courseIdentifier?: InputMaybe<Scalars['String']>;
  dashboardMode?: InputMaybe<Scalars['String']>;
  deleteFile?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<UpsertLangStringInput>;
  duration?: InputMaybe<Scalars['Float']>;
  expirationDate?: InputMaybe<Scalars['Instant']>;
  file?: InputMaybe<Scalars['Upload']>;
  fileDocument?: InputMaybe<Scalars['Upload']>;
  fileSubtitle?: InputMaybe<Scalars['Upload']>;
  fileVideo?: InputMaybe<Scalars['Upload']>;
  idOrKey: IdOrKeyInput;
  identifier?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isLearningPath?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<UpsertKeyedCollection2OfUpsertScoInput>;
  key?: InputMaybe<Scalars['Key']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<UpsertLangStringInput>;
  playOnLoop?: InputMaybe<Scalars['Boolean']>;
  publishStatus?: InputMaybe<PublishStatus>;
  type?: InputMaybe<Scalars['String']>;
  typicalLearningTime?: InputMaybe<Scalars['Duration']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertGroupInput = {
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  name?: InputMaybe<UpsertLangStringInput>;
  status: GroupStatus;
  unitId?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<UpsertLinksInput>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertGroupRoleInput = {
  roleLinks?: InputMaybe<Array<InputMaybe<UpsertRoleAssignmentInput>>>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type UpsertIconInput = {
  idOrKey: IdOrKeyInput;
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  titleDe?: InputMaybe<Scalars['String']>;
  titleEn?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertKeyedCollection2OfUpsertScoInput = {
  add?: InputMaybe<Array<InputMaybe<UpsertScoInput>>>;
  remove?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
  set?: InputMaybe<Array<InputMaybe<UpsertScoInput>>>;
};

export type UpsertLangStringInput = {
  add?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  neutral?: InputMaybe<Scalars['String']>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  set?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
};

export type UpsertLinksInput = {
  add?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
  remove?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
  set?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
};

export type UpsertMailTemplateInput = {
  additionalInfoDe?: InputMaybe<Scalars['String']>;
  additionalInfoEn?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  mainTextDe?: InputMaybe<Scalars['String']>;
  mainTextEn?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<UpsertLangStringInput>;
  nameDE?: InputMaybe<Scalars['String']>;
  nameEN?: InputMaybe<Scalars['String']>;
  subjectDe?: InputMaybe<Scalars['String']>;
  subjectEn?: InputMaybe<Scalars['String']>;
  templateType: EmailTemplateEnum;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertNuggetInputsInput = {
  courseId: Scalars['Int'];
  description?: InputMaybe<UpsertLangStringInput>;
  id?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isReusable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Key']>;
  learningTime?: InputMaybe<Scalars['Duration']>;
  order: Scalars['Int'];
  parentId?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<UpsertLangStringInput>;
  type?: InputMaybe<Scalars['String']>;
  uploadFile?: InputMaybe<Scalars['Upload']>;
  uploadIcon?: InputMaybe<Scalars['Upload']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertOrgGroupsInput = {
  /** OrgGroup IdorKey */
  name?: InputMaybe<Scalars['String']>;
  /** Ids or Keys for OrgGroup roles */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpsertRef2Input = {
  idOrKey: IdOrKeyInput;
};

export type UpsertRoleAssignmentInput = {
  add?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  group: Scalars['String'];
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  set?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpsertRoleInput = {
  description?: InputMaybe<UpsertLangStringInput>;
  idOrKey: Scalars['ID'];
  key?: InputMaybe<Scalars['Key']>;
  name?: InputMaybe<UpsertLangStringInput>;
  privilegeLinks?: InputMaybe<UpsertCollectionOfStringInput>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertScoInput = {
  courseLink?: InputMaybe<IdOrKeyInput>;
  dataFromLMS?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<UpsertLangStringInput>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']>;
  launchUri?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<UpsertLangStringInput>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type UpsertUserInput = {
  adminRole?: InputMaybe<Scalars['Boolean']>;
  deleteFile?: InputMaybe<Scalars['Boolean']>;
  deleteUser?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupsAndRoles?: InputMaybe<Scalars['String']>;
  idOrKey: IdOrKeyInput;
  importStatus?: InputMaybe<Scalars['String']>;
  importStatusOverwritten?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['Key']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  roleLinks?: InputMaybe<Array<InputMaybe<UpsertRoleAssignmentInput>>>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

/** A User */
export type User = {
  __typename?: 'User';
  /** Admin role flag */
  admin: Scalars['Boolean'];
  /** All groups */
  allGroups: Array<Maybe<Group>>;
  /** Assigned groups */
  assignedGroups: Scalars['Int'];
  /** Badges accomplished from user */
  badges: Array<Maybe<Badges>>;
  /** Completed Badges */
  badgesCompleted: Scalars['Int'];
  /** Certificates accomplished from user */
  certificates: Array<Maybe<Certificate>>;
  /** Completed certificates */
  certificatesCompleted: Scalars['Int'];
  /** Defines email of the User */
  email: Scalars['String'];
  /** External id */
  externalId?: Maybe<Scalars['String']>;
  /** Defines first name of the User */
  firstName: Scalars['String'];
  /** Groups assigned to the user */
  groups: Array<Maybe<Group>>;
  /** Groups and roles assigned to the user */
  groupsAndRoles: Array<Maybe<UserAuthorizationGql>>;
  /** Groups not assigned to the user */
  groupsNotAssigned: Array<Maybe<Group>>;
  /** Image URL */
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Image base64 */
  imageBase64?: Maybe<Scalars['String']>;
  /** Is user anonymized */
  isAnonymized: Scalars['Boolean'];
  /** Joined date */
  joinedDate?: Maybe<Scalars['String']>;
  key: Scalars['Key'];
  /** Defines last name of the User */
  lastName: Scalars['String'];
  /** Defines the location */
  location: Scalars['String'];
  /** Defines full name of the User */
  name: Scalars['String'];
  /** Defines the phone */
  phone: Scalars['String'];
  /** Associated Role to a Group + User. */
  roles: Array<Maybe<Role>>;
  scorm12EnrolledCourses: Array<Maybe<Scorm12EnrolledCourse>>;
  /** Associated self registration link title */
  selfRegistrationLinkTitle: Scalars['String'];
  showDeadlinesAsAppointment: Scalars['Boolean'];
  /** User status */
  status: UserStatus;
  /** Associated self registration link title */
  systemLanguage: Array<Maybe<Scalars['String']>>;
  /** Defines the username */
  username: Scalars['String'];
  uuid: Scalars['UUID'];
};


/** A User */
export type UserScorm12EnrolledCoursesArgs = {
  filter?: InputMaybe<UserEnrolledCourseInput>;
};

export type UserAuthorizationGql = {
  __typename?: 'UserAuthorizationGQL';
  authorizationLevel?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['Key'];
  role?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export type UserBadgesDto = {
  __typename?: 'UserBadgesDto';
  badgeId: Scalars['Int'];
  badgeType?: Maybe<Scalars['String']>;
  badgeTypeCount: Scalars['Int'];
  courseName?: Maybe<LangString>;
  currentLevel: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UserConnectionGql = {
  __typename?: 'UserConnectionGQL';
  edges: Array<UserEdgeGql>;
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int'];
};

/** User Details Statistics */
export type UserDetailsStatisticsGql = {
  __typename?: 'UserDetailsStatisticsGQL';
  /** Calls */
  calls: Scalars['Int'];
  /** Completion date */
  completion: Scalars['String'];
  /** Contact roles */
  contactRoles: Scalars['String'];
  /** Course Item Identifier */
  courseItemIdentifier: Scalars['String'];
  /** Course title */
  courseTitle: LangString;
  /** Creation date */
  creationDate: Scalars['String'];
  /** Current result */
  currentResult: Scalars['Float'];
  /** End date */
  endDate: Scalars['String'];
  /** Group assigned */
  group?: Maybe<Group>;
  /** ID */
  id: Scalars['String'];
  /** Intervall */
  intervall: Scalars['String'];
  /** Mandatory flag */
  mandatory: Scalars['Boolean'];
  /** Recurring flag */
  recurring: Scalars['Boolean'];
  /** result */
  results: Scalars['Float'];
  /** Start date */
  startDate: Scalars['String'];
  /** Status */
  status: Scalars['String'];
};

export type UserDto = {
  __typename?: 'UserDto';
  authorizationTokenKey?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isAnonymized?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  scorm12EnrolledCourses?: Maybe<Array<Maybe<Scorm12EnrolledCourseDto>>>;
  status: UserStatus;
  statusDate?: Maybe<Scalars['Instant']>;
  updatedDate?: Maybe<Scalars['Instant']>;
  userSelfRegistrationId?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type UserEdgeGql = {
  __typename?: 'UserEdgeGQL';
  cursor: Scalars['String'];
  node: User;
};

export type UserEnrolledCourseInput = {
  completionStatus: CourseCompletionStatus;
  courseLinks?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
};

/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutation = {
  __typename?: 'UserMutation';
  adminRights: User;
  anonymize: User;
  assignUnitManagers: Unit;
  bulkAnonymize: Array<Maybe<User>>;
  bulkRemove: Scalars['Boolean'];
  bulkResendVerificationEmail: Scalars['Boolean'];
  bulkUpdateGroups: Array<Maybe<User>>;
  bulkUpdateUserStatus: Array<Maybe<User>>;
  changeDeadLineViewModel: Scalars['Boolean'];
  configureLanguage: User;
  importUsers: ImportUsers;
  insertUserIfNotExistsInSitos: AssignmentTokenDto;
  isEducationalManager: Scalars['Boolean'];
  isLearner: Scalars['Boolean'];
  promoteUser: User;
  remove: Scalars['Boolean'];
  removeRights: User;
  resendVerificationEmail: Scalars['Boolean'];
  updateCountUserLogin: Scalars['Boolean'];
  updateGroups: User;
  updateUserStatus: User;
  upsert: User;
  upsertGroupRoles: User;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationAdminRightsArgs = {
  input: UpsertUserInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationAnonymizeArgs = {
  input?: InputMaybe<UpsertRef2Input>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationAssignUnitManagersArgs = {
  input?: InputMaybe<AddUnitLearningManagersInput>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationBulkAnonymizeArgs = {
  input?: InputMaybe<BulkAnonymizeUserInput>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationBulkRemoveArgs = {
  input: BulkDeleteUserInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationBulkResendVerificationEmailArgs = {
  input?: InputMaybe<BulkResendVerificationEmailInput>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationBulkUpdateGroupsArgs = {
  input: BulkUpdateUserGroupsInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationBulkUpdateUserStatusArgs = {
  input: BulkUpdateUserStatusInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationConfigureLanguageArgs = {
  languages: Array<InputMaybe<Scalars['String']>>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationImportUsersArgs = {
  input: ImportUserLogsInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationInsertUserIfNotExistsInSitosArgs = {
  externalId?: InputMaybe<Scalars['String']>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationIsEducationalManagerArgs = {
  role?: InputMaybe<Scalars['String']>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationIsLearnerArgs = {
  role?: InputMaybe<Scalars['String']>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationPromoteUserArgs = {
  input?: InputMaybe<PromoteUserInput>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationRemoveArgs = {
  input: UpsertRef2Input;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationRemoveRightsArgs = {
  input?: InputMaybe<DeleteUnitLearningManagerInput>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationResendVerificationEmailArgs = {
  input?: InputMaybe<UpsertRef2Input>;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationUpdateGroupsArgs = {
  input: UpdateUserGroupsInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationUpsertArgs = {
  input: UpsertUserInput;
};


/** GraphQl mutation for updating/upserting, importing, removing and anonymizing Users */
export type UserMutationUpsertGroupRolesArgs = {
  input: UpsertGroupRoleInput;
};

/** To show data from attempt and sco in one object */
export type UserStaticsLesson = {
  __typename?: 'UserStaticsLesson';
  /** Shows how often the lesson was called by the learner */
  calls: Scalars['Int'];
  /** Shows if lesson is optional or required for completion */
  isMandatory?: Maybe<Scalars['Boolean']>;
  /** Shows when the lesson was last called */
  lastCall?: Maybe<Scalars['Instant']>;
  /** Date of completion of lesson item */
  lessonCompletionDate?: Maybe<Scalars['Instant']>;
  /** Lesson title */
  lessonTitle?: Maybe<LangString>;
  /** Parent title */
  parentTitle?: Maybe<LangString>;
  /** Result in % */
  resultInPercentage: Scalars['Float'];
  /** Complete / Incomplete / Passed / Failed / not attempted */
  status: Scorm12_LessonStatus;
  /** Shows the amount of time spent by the learner on the lessons */
  timeSpent?: Maybe<Scalars['Duration']>;
};

/** User Statistics */
export type UserStatisticsOverviewGql = {
  __typename?: 'UserStatisticsOverviewGQL';
  /** Number of completed courses */
  completedCourses: Scalars['Int'];
  /** Firstname of the user */
  firstName: Scalars['String'];
  /** Is user anonymized */
  isAnonymized: Scalars['Boolean'];
  /** Last course call (Date and Time) */
  lastCourseCall: Scalars['String'];
  /** Last course called (Coursename) */
  lastCourseCalledName?: Maybe<LangString>;
  /** Lastname of the user */
  lastName: Scalars['String'];
  /** Number of enrollments */
  numberOfEnrollments: Scalars['Int'];
  /** Number of open in progress courses */
  openInProgressCourses: Scalars['Int'];
  /** Assigned courses */
  userDetailsStatistics: Array<Maybe<UserDetailsStatisticsGql>>;
  /** UUID */
  uuid: Scalars['UUID'];
};

export enum UserStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Pending = 'PENDING'
}

export type UsersFiltersInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  maxGroups?: InputMaybe<Scalars['Int']>;
  minGroups?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<UserStatus>;
};

export type UsersStatisticsFiltersInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastCall?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  maxAssignmentsCount?: InputMaybe<Scalars['Int']>;
  maxCalledOnDate?: InputMaybe<Scalars['Instant']>;
  maxCompletedCount?: InputMaybe<Scalars['Int']>;
  maxNotStartedCount?: InputMaybe<Scalars['Int']>;
  minAssignmentsCount?: InputMaybe<Scalars['Int']>;
  minCalledOnDate?: InputMaybe<Scalars['Instant']>;
  minCompletedCount?: InputMaybe<Scalars['Int']>;
  minNotStartedCount?: InputMaybe<Scalars['Int']>;
};

export type WidgetInput = {
  height?: InputMaybe<Scalars['Float']>;
  idOrKey: IdOrKeyInput;
  isAdditional: Scalars['Boolean'];
  key?: InputMaybe<Scalars['Key']>;
  position?: InputMaybe<PositionInput>;
  roleId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<UpsertLangStringInput>;
  type: WidgetType;
  userId?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['UUID']>;
  width?: InputMaybe<Scalars['Float']>;
};

/** GraphQl mutation for updating/upserting and setting default Widgets */
export type WidgetMutation = {
  __typename?: 'WidgetMutation';
  upsert: Widgets;
  upsertAllWidgets: Array<Maybe<Widgets>>;
};


/** GraphQl mutation for updating/upserting and setting default Widgets */
export type WidgetMutationUpsertArgs = {
  input: WidgetInput;
};


/** GraphQl mutation for updating/upserting and setting default Widgets */
export type WidgetMutationUpsertAllWidgetsArgs = {
  inputs: Array<InputMaybe<WidgetInput>>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum WidgetType {
  Calendar = 'CALENDAR',
  Completions = 'COMPLETIONS',
  CourseRecommendation = 'COURSE_RECOMMENDATION',
  GetBackIn = 'GET_BACK_IN',
  LastCompletions = 'LAST_COMPLETIONS',
  LearningActivity = 'LEARNING_ACTIVITY',
  MyCertificates = 'MY_CERTIFICATES',
  MyCourseHistory = 'MY_COURSE_HISTORY',
  NewestMembers = 'NEWEST_MEMBERS',
  News = 'NEWS',
  NewsFromTheCatalogue = 'NEWS_FROM_THE_CATALOGUE',
  QuickLinks = 'QUICK_LINKS',
  Welcome = 'WELCOME'
}

/** A widget */
export type Widgets = {
  __typename?: 'Widgets';
  /** Defines height of the widget */
  height: Scalars['Float'];
  id: Scalars['ID'];
  /** Defines isAdditional of the widget */
  isAdditional: Scalars['Boolean'];
  key: Scalars['Key'];
  position?: Maybe<Position>;
  /** Defines title of the widget */
  title: LangString;
  /** Defines type of the widget */
  type: WidgetType;
  uuid: Scalars['UUID'];
  /** Defines width of the widget */
  width: Scalars['Float'];
};

export type WidgetsConnectionGql = {
  __typename?: 'WidgetsConnectionGQL';
  edges: Array<WidgetsEdgeGql>;
  nodes: Array<Widgets>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WidgetsEdgeGql = {
  __typename?: 'WidgetsEdgeGQL';
  cursor: Scalars['String'];
  node: Widgets;
};

/** Mutation for zulip chat */
export type ZulipChatMutation = {
  __typename?: 'ZulipChatMutation';
  createStream: ZulipStreamGql;
  deactivateUser: Scalars['Boolean'];
  muteTopic: Scalars['Boolean'];
  sendMessages: ZulipStreamGql;
};


/** Mutation for zulip chat */
export type ZulipChatMutationCreateStreamArgs = {
  input: ZulipStreamInput;
};


/** Mutation for zulip chat */
export type ZulipChatMutationDeactivateUserArgs = {
  deactivationNotificationComment?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
};


/** Mutation for zulip chat */
export type ZulipChatMutationMuteTopicArgs = {
  courseUuid: Scalars['String'];
  topic: Scalars['String'];
};


/** Mutation for zulip chat */
export type ZulipChatMutationSendMessagesArgs = {
  input: ZulipMessageInput;
};

/** Zulip chat message */
export type ZulipMessageGql = {
  __typename?: 'ZulipMessageGQL';
  avatar?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  id: Scalars['Int'];
  isMine: Scalars['Boolean'];
  senderEmail?: Maybe<Scalars['String']>;
  senderFullName: Scalars['String'];
  timestamp: Scalars['Long'];
  topic?: Maybe<Scalars['String']>;
};

export type ZulipMessageInput = {
  courseUuid?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

/** Zulip chat stream */
export type ZulipStreamGql = {
  __typename?: 'ZulipStreamGQL';
  /** Messages in the stream */
  messages?: Maybe<Array<Maybe<ZulipMessageGql>>>;
  name: Scalars['String'];
};

export type ZulipStreamInput = {
  courseUuid?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

/** Zulip chat message */
export type ZulipTopicGql = {
  __typename?: 'ZulipTopicGQL';
  name: Scalars['String'];
};

export type GetVersionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVersionQuery = { __typename?: 'Query', version: string };

export const GetVersionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVersion"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"version"}}]}}]} as unknown as DocumentNode;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVersionGQL extends Apollo.Query<GetVersionQuery, GetVersionQueryVariables> {
    document = GetVersionDocument;
    client = 'sitosxapi';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }