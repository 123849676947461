<body>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-/bQdsTh/da6pkI1MST/rWKFNjaCP5gBSY4sEBT38Q/9RBh9AH40zEOg7Hlq2THRZ" crossorigin="anonymous"></script>
</body>
<dx-scroll-view
  *ngIf="(renderScroolView$ | async)"
  role="main"
  class="full-height-scrollable"
  [showScrollbar]="showScrollbar"
  [direction]="'both'"
  (onScroll)="appService.onScroll.next()"
>
  <router-outlet></router-outlet>
</dx-scroll-view>

<router-outlet *ngIf="!(renderScroolView$ | async)"></router-outlet>
<!-- <div class="version">
  <p>UI: {{appUIVersion}}</p>
  <p>API: {{appAPIVersion}}</p>
</div> -->
