import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn:'root'
})
export class appService {
    constructor() { }

    private emitChangeSource = new Subject<any>();

    changeEmitted$ = this.emitChangeSource.asObservable();

    emitChange(arg:any) {
        this.emitChangeSource.next(arg);
    }

    scrollVisibility = new BehaviorSubject<boolean>(true);
    onScroll = new Subject<void>();

}
