import { Injectable } from '@angular/core';
import themes from 'devextreme/ui/themes';
import { THEMES } from '../constants/themes';
import DxThemes from 'devextreme/ui/themes';

// refactor
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: string = '';



  constructor() {}

  setAppTheme(themeName: string) {
    const theme = THEMES.find((theme) => theme.name === themeName) || THEMES[0];
    theme.baseTheme === this.currentTheme ? themes.current(theme.baseTheme) : this.switchLocalTheme(theme.baseTheme);
    DxThemes.current(theme.baseTheme);
  }

  switchLocalTheme(themeName: string) {
    this.currentTheme = themeName;
  }

}
