import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/services/guard/authGuard';
import { GraphQLModule } from './graphql.module';
import {
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DxScrollViewModule } from 'devextreme-angular';
import { CustomTranslateHttpLoader } from './shared/services/http-loader';
import { appInitializer } from './core/services/app-initializer';
import { DateTimeService, TranslationService, AuthService, AUTH_SERVICE_CONFIG_TOKEN, AuthServiceConfig } from './core/services';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { ThemeService } from './shared/services/theme.service';
import { appService } from './appService';
import { RECOMMENDER_CONFIGURATION, RecommenderConfiguration } from './core/injection-tokens';
import { environment } from "../environments/environment";

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, './assets/i18n/', '.json');
}


export function storageFactory(): OAuthStorage {
  return localStorage;
}

// TODO: refactor config setup(?)
export function oAuthConfigFactory(): OAuthModuleConfig {
  const idmUrl = window.__CONFIG?.IDM_ISSUER || '';
  if (!idmUrl) {
    console.error('invalid config - IDM_ISSUER not set');
  }
  return {
    resourceServer: {
      allowedUrls: [idmUrl],
      sendAccessToken: true,
    },
  };
}

const recommendationConfig: RecommenderConfiguration = {
  recommenderApiUrl: environment.recommenderApiUrl,
  domainId: environment.domainId,
  apiKey: environment.apiKey,
  scenarioName: environment.scenarioName,
  recommendationCount: environment.recommendationCount
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    DxScrollViewModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    })

  ],
  providers: [
    TranslateService,
    appService,
    AuthGuard,
    {
      provide: OAuthModuleConfig,
      useFactory: oAuthConfigFactory,
    },
    {
      provide: OAuthStorage,
      useFactory: storageFactory
    },
    {
      provide: AUTH_SERVICE_CONFIG_TOKEN,
      useFactory: (): AuthServiceConfig => {
        const globalConfig = window.__CONFIG;
        return {
          clientId: globalConfig?.IDM_CLIENTID || '',
          issuer: globalConfig?.IDM_ISSUER || '',
          scope: globalConfig?.IDM_SCOPE || '',
          requireHTTPS: globalConfig?.REQUIRE_HTTPS || '',
          responseType: globalConfig?.IDM_RESPONSE_TYPE || '',
        };
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, TranslationService, DateTimeService, ThemeService],
      multi: true,
    },
    {
      provide: RECOMMENDER_CONFIGURATION,
      useValue: recommendationConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
