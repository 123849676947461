import { Injectable } from '@angular/core';
import { CountUserLoginForBadgeGQL}  from './countUserLoginForBadge.mutation.sitos-x-api.g';

@Injectable({
  providedIn: 'root',
})

export class UserCountLoginService {

  constructor(
    private countUserLoginForBadgeGQL: CountUserLoginForBadgeGQL
  ) {}

  updateCountUserLogin () {
    this.countUserLoginForBadgeGQL.mutate().subscribe();
  }
}
