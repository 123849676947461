declare global {
  interface Window {
    __CONFIG?: {
      API_URL?: string;
      ALLOWED_LOCALES?: string;
      APP_LOCALE?: string;
      DEFAULT_THEME?: string;
      IDM_ISSUER?: string;
      IDM_CLIENTID?: string;
      IDM_SCOPE?: string;
      IDM_CLIENT_SECRET?: string;
      REQUIRE_HTTPS?: string | boolean;
      IDM_RESPONSE_TYPE?: string;
    };
  }
}

function appendGraphQL(url: string) {
  if (!url) {
    return url;
  }
  if (url.endsWith('/graphql')) {
    return url;
  }
  return new URL('graphql', url).toString();
}
export const apiUrl = appendGraphQL(window.__CONFIG?.API_URL || '');
if (!apiUrl) {
  console.error(`window.__CONFIG.API_URL not set`);
}
export const apiBaseUrl = new URL('..', apiUrl);
export const uploadUrl = new URL('api/upload', apiBaseUrl).toString();
console.info(
  `Sending API requests to ${apiUrl}, sending uploads to ${uploadUrl}`
);
