import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/guard/authGuard';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'media',
        loadChildren: () => import('./my-media/my-media.module').then((m) => m.MyMediaModule),
        canActivate: [AuthGuard],
        data: {
            role: ['LEARNER'],
        },
    },
    {
        path: 'catalogue',
        loadChildren: () => import('./catalogue/catalogue.module').then((m) => m.CatalogueModule),
        canActivate: [AuthGuard],
        data: {
            role: ['LEARNER'],
        },
    },
    {
        path: 'media-management',
        loadChildren: () =>
            import('./media-administration/media-management/media-management.module').then(
                (m) => m.MediaManagementModule
            ),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'category-management',
        loadChildren: () =>
            import('./media-administration/category-management/category-management.module').then(
                (m) => m.CategoryManagementModule
            ),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'media-statistics',
        loadChildren: () =>
            import('./statistics/media-statistics/media-statistics.module').then((m) => m.StatisticsModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'user-statistics',
        loadChildren: () =>
            import('./statistics/user-statistics/user-statistics.module').then((m) => m.UserStatisticsModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'unit-management',
        loadChildren: () =>
            import('./user-administration/unit-management/unit-group-management.module').then(
                (m) => m.UnitGroupManagementModule
            ),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'user-management',
        loadChildren: () =>
            import('./user-administration/user-management/user-management.module').then((m) => m.UserManagementModule),
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'import-management',
        loadChildren: () =>
            import('./user-administration/import-management/import-management.module').then(
                (m) => m.ImportManagementModule
            ),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'assignments',
        loadChildren: () =>
            import('./user-administration/assignments/assignments.module').then((m) => m.AssignmentsModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'mailtemplates',
        loadChildren: () =>
            import('./configuration/mailtemplates/mailtemplate.module').then((m) => m.MailtemplateModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'certificates',
        loadChildren: () =>
            import('./configuration/certificates/certificates.module').then((m) => m.CertificatesModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'system',
        loadChildren: () => import('./configuration/system/system.module').then((m) => m.SystemModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'my-profile',
        loadChildren: () => import('./my-profile/my-profile.module').then((m) => m.MyProfileModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'my-certificates',
        loadChildren: () =>
            import('./my-profile/my-certificates/my-certificates.module').then((m) => m.MyCertificatesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'my-units',
        loadChildren: () => import('./my-profile/my-groups/my-groups.module').then((m) => m.MyGroupsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'design-configuration',
        loadChildren: () => import('./configuration/design/design.module').then((m) => m.DesignModule),
        data: {
            role: ['ADMIN'],
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'super-console',
        loadChildren: () =>
            import('./configuration/super-console/super-console.module').then((m) => m.SuperConsoleModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'help-and-legal',
        loadChildren: () =>
            import('./help-and-legal-notice/help-and-legal-notice.module').then((m) => m.HelpAndLegalNoticeModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'using-sitos',
        loadChildren: () => import('./using-sitos/using-sitos.module').then((m) => m.UsingSitosModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'assistant',
        loadChildren: () => import('./assistant/assistant.module').then((m) => m.AssistantModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER', 'LEARNER'],
        },
    },
    {
        path: 'system-badges',
        loadChildren: () =>
            import('./configuration/badges/system-badges/system-badges.module').then((m) => m.SystemBadgesModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'course-badges',
        loadChildren: () =>
            import('./configuration/badges/course-badges/course-badges.module').then((m) => m.CourseBadgesModule),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'badges-collection',
        loadChildren: () =>
            import('./my-profile/badges-collection/badges-collection.module').then((m) => m.BadgesCollectionModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'communication',
        loadChildren: () => import('./news-administration/news/news.module').then((m) => m.NewsModule),
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
    },
    {
        path: 'learning-path',
        loadChildren: () =>
            import('./media-administration/learning-path/learning-path.module').then((m) => m.LearningPathModule),
        data: {
            role: ['ADMIN', 'EDUCATION_MANAGER'],
        },
        canActivate: [AuthGuard],
    },

    {
        path: 'relatives',
        loadChildren: () => import('./parent-administration/parents.module').then((m) => m.ParentsModule),
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'error-page',
        loadChildren: () => import('./error-page/error-page.module').then((m) => m.ErrorPageModule),
    },
    {
        path: '**',
        redirectTo: 'error-page',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
